import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Welcome',
    component: () => import('../views/Welcome.vue'),
  },
  {
    path: '/inactive-link',
    name: 'InactiveLink',
    component: () => import('../views/InactiveLink.vue'),
  },
  {
    path: '/start',
    name: 'Start',
    component: () => import('../views/Start.vue'),
  },
  {
    path: '/how-to-use',
    name: 'HowToUse',
    component: () => import('../views/HowToUse.vue'),
  },
  {
    path: '/population',
    name: 'Population',
    component: () => import('../views/Population.vue'),
  },
  {
    path: '/population-manual',
    name: 'PopulationManual',
    component: () => import('../views/PopulationManual.vue'),
  },
  {
    path: '/drug-timeframe',
    name: 'DrugTimeframe',
    component: () => import('../views/DrugsTimeframe.vue'),
  },
  {
    path: '/costs',
    name: 'Costs',
    component: () => import('../views/Costs.vue'),
  },
  {
    path: '/dosing',
    name: 'Dosing',
    component: () => import('../views/Dosing.vue'),
  },
  {
    path: '/patient-numbers',
    name: 'PatientNumbers',
    component: () => import('../views/PatientNumbers.vue'),
  },
  {
    path: '/share',
    name: 'Share',
    component: () => import('../views/Share.vue'),
  },
  {
    path: '/share-switch',
    name: 'ShareSwitch',
    component: () => import('../views/ShareSwitch.vue'),
  },
  {
    path: '/summary',
    name: 'Summary',
    component: () => import('../views/Summary.vue'),
  },
  {
    path: '/instructions',
    name: 'Instructions',
    component: () => import('../views/Instructions.vue'),
  },
  {
    path: '/indications',
    name: 'Indications',
    component: () => import('../views/Indications.vue'),
  },
  {
    path: '/references',
    name: 'References',
    component: () => import('../views/References.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
