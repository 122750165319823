import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    drugs: [],
    hash: '',
    hospital: '',
    id: 0,
    indications: [],
    page: 'overview',
    patientTypes: [],
    population: 0,
    manualPopulation: false,
    country: '',
    region: '',
    organisation: '',
    timeframe: 1,
    biosimilarPrice: 0,
    byovizDiscount: 0,
    lucentisDiscount: 0,
    biosimilarDiscount: 0,
    byovizLocalPrice: 0,
    lucentisLocalPrice: 0,
    biosimilarLocalPrice: 0,
    biosimilarNetCost: 0,
    byovizNetCost: 0,
    lucentisNetCost: 0,
    byovizWamdLoading: 3,
    byovizWamdMaintenance: 3,
    byovizWamdContinuation: 13,
    byovizDmeLoading: 3,
    byovizDmeMaintenance: 3,
    byovizDmeContinuation: 13,
    byovizPdrLoading: 3,
    byovizPdrMaintenance: 3,
    byovizPdrContinuation: 13,
    byovizRvoLoading: 3,
    byovizRvoMaintenance: 3,
    byovizRvoContinuation: 13,
    byovizCnvTotal: 6,
    byovizCnvContinuation: 12,
    lucentisWamdLoading: 3,
    lucentisWamdMaintenance: 3,
    lucentisWamdContinuation: 13,
    lucentisDmeLoading: 3,
    lucentisDmeMaintenance: 3,
    lucentisDmeContinuation: 13,
    lucentisPdrLoading: 3,
    lucentisPdrMaintenance: 3,
    lucentisPdrContinuation: 13,
    lucentisRvoLoading: 3,
    lucentisRvoMaintenance: 3,
    lucentisRvoContinuation: 13,
    lucentisCnvTotal: 6,
    lucentisCnvContinuation: 12,
    biosimilarWamdLoading: 3,
    biosimilarWamdMaintenance: 3,
    biosimilarWamdContinuation: 13,
    biosimilarDmeLoading: 3,
    biosimilarDmeMaintenance: 3,
    biosimilarDmeContinuation: 13,
    biosimilarPdrLoading: 3,
    biosimilarPdrMaintenance: 3,
    biosimilarPdrContinuation: 13,
    biosimilarRvoLoading: 3,
    biosimilarRvoMaintenance: 3,
    biosimilarRvoContinuation: 13,
    biosimilarCnvTotal: 6,
    biosimilarCnvContinuation: 12,
    byovizYearOneWamdDose: 0,
    byovizContinuationWamdDose: 0,
    byovizYearOneDmeDose: 0,
    byovizContinuationDmeDose: 0,
    byovizYearOnePdrDose: 0,
    byovizContinuationPdrDose: 0,
    byovizYearOneRvoDose: 0,
    byovizContinuationRvoDose: 0,
    byovizYearOneCnvDose: 0,
    byovizContinuationCnvDose: 0,
    lucentisYearOneWamdDose: 0,
    lucentisContinuationWamdDose: 0,
    lucentisYearOneDmeDose: 0,
    lucentisContinuationDmeDose: 0,
    lucentisYearOnePdrDose: 0,
    lucentisContinuationPdrDose: 0,
    lucentisYearOneRvoDose: 0,
    lucentisContinuationRvoDose: 0,
    lucentisYearOneCnvDose: 0,
    lucentisContinuationCnvDose: 0,
    biosimilarYearOneWamdDose: 0,
    biosimilarContinuationWamdDose: 0,
    biosimilarYearOneDmeDose: 0,
    biosimilarContinuationDmeDose: 0,
    biosimilarYearOnePdrDose: 0,
    biosimilarContinuationPdrDose: 0,
    biosimilarYearOneRvoDose: 0,
    biosimilarContinuationRvoDose: 0,
    biosimilarYearOneCnvDose: 0,
    biosimilarContinuationCnvDose: 0,
    incidenceOfWamd: 0.0686,
    prevalenceOfWamd: 0.505,
    incidenceOfDme: 0.0063,
    prevalenceOfDme: 0.146,
    incidenceOfPdr: 0.007,
    prevalenceOfPdr: 0.213,
    incidenceOfRvo: 0.163,
    prevalenceOfRvo: 0.350,
    incidenceOfCnv: 0.005,
    prevalenceOfCnv: 0.488,
    proportionOfPatientsDrugTreatedWamd: 0,
    proportionOfDrugTreatedPatientsReceivingRanibizumabWamd: 0,
    proportionOfPatientsDrugTreatedDme: 0,
    proportionOfDrugTreatedPatientsReceivingRanibizumabDme: 0,
    proportionOfPatientsDrugTreatedPdr: 0,
    proportionOfDrugTreatedPatientsReceivingRanibizumabPdr: 0,
    proportionOfPatientsDrugTreatedRvo: 0,
    proportionOfDrugTreatedPatientsReceivingRanibizumabRvo: 0,
    proportionOfPatientsDrugTreatedCnv: 0,
    proportionOfDrugTreatedPatientsReceivingRanibizumabCnv: 0,
    numberOfNewPatientsCurrentlyTreatedWithRanibizumabWamd: 0,
    numberOfPatientsCurrentlyTreatedWithRanibizumabWamd: 0,
    numberOfNewPatientsCurrentlyTreatedWithRanibizumabDme: 0,
    numberOfPatientsCurrentlyTreatedWithRanibizumabDme: 0,
    numberOfNewPatientsCurrentlyTreatedWithRanibizumabPdr: 0,
    numberOfPatientsCurrentlyTreatedWithRanibizumabPdr: 0,
    numberOfNewPatientsCurrentlyTreatedWithRanibizumabRvo: 0,
    numberOfPatientsCurrentlyTreatedWithRanibizumabRvo: 0,
    numberOfNewPatientsCurrentlyTreatedWithRanibizumabCnv: 0,
    numberOfPatientsCurrentlyTreatedWithRanibizumabCnv: 0,
    averageNumberOfEyesAffectedWamd: 1,
    averageNumberOfEyesAffectedDme: 1,
    averageNumberOfEyesAffectedPdr: 1,
    averageNumberOfEyesAffectedRvo: 1,
    averageNumberOfEyesAffectedCnv: 1,
    byovizYearOneShare: 0,
    byovizYearTwoShare: 0,
    byovizYearThreeShare: 0,
    lucentisYearOneShare: 0,
    lucentisYearTwoShare: 0,
    lucentisYearThreeShare: 0,
    biosimilarYearOneShare: 0,
    biosimilarYearTwoShare: 0,
    biosimilarYearThreeShare: 0,
    byovizYearOneFutureShare: 0,
    byovizYearTwoFutureShare: 0,
    byovizYearThreeFutureShare: 0,
    lucentisYearOneFutureShare: 0,
    lucentisYearTwoFutureShare: 0,
    lucentisYearThreeFutureShare: 0,
    biosimilarYearOneFutureShare: 0,
    biosimilarYearTwoFutureShare: 0,
    biosimilarYearThreeFutureShare: 0,
    yearOneTotalShare: 0,
    yearTwoTotalShare: 0,
    yearThreeTotalShare: 0,
    yearOneTotalShareFuture: 0,
    yearTwoTotalShareFuture: 0,
    yearThreeTotalShareFuture: 0,
    byovizYearOneShareSwitch: 0,
    byovizYearTwoShareSwitch: 0,
    byovizYearThreeShareSwitch: 0,
    lucentisYearOneShareSwitch: 0,
    lucentisYearTwoShareSwitch: 0,
    lucentisYearThreeShareSwitch: 0,
    biosimilarYearOneShareSwitch: 0,
    biosimilarYearTwoShareSwitch: 0,
    biosimilarYearThreeShareSwitch: 0,
    byovizYearOneFutureShareSwitch: 0,
    byovizYearTwoFutureShareSwitch: 0,
    byovizYearThreeFutureShareSwitch: 0,
    lucentisYearOneFutureShareSwitch: 0,
    lucentisYearTwoFutureShareSwitch: 0,
    lucentisYearThreeFutureShareSwitch: 0,
    biosimilarYearOneFutureShareSwitch: 0,
    biosimilarYearTwoFutureShareSwitch: 0,
    biosimilarYearThreeFutureShareSwitch: 0,
    yearOneTotalShareSwitch: 0,
    yearTwoTotalShareSwitch: 0,
    yearThreeTotalShareSwitch: 0,
    yearOneTotalShareFutureSwitch: 0,
    yearTwoTotalShareFutureSwitch: 0,
    yearThreeTotalShareFutureSwitch: 0,
    speedOfImplementation: 0,
    byoovizWamdYearOneFuturePatients: 0,
    byoovizWamdYearTwoFuturePatients: 0,
    byoovizWamdYearThreeFuturePatients: 0,
    byoovizDmeYearOneFuturePatients: 0,
    byoovizDmeYearTwoFuturePatients: 0,
    byoovizDmeYearThreeFuturePatients: 0,
    byoovizPdrYearOneFuturePatients: 0,
    byoovizPdrYearTwoFuturePatients: 0,
    byoovizPdrYearThreeFuturePatients: 0,
    byoovizRvoYearOneFuturePatients: 0,
    byoovizRvoYearTwoFuturePatients: 0,
    byoovizRvoYearThreeFuturePatients: 0,
    byoovizCnvYearOneFuturePatients: 0,
    byoovizCnvYearTwoFuturePatients: 0,
    byoovizCnvYearThreeFuturePatients: 0,
    biosimilarWamdYearOneFuturePatients: 0,
    biosimilarWamdYearTwoFuturePatients: 0,
    biosimilarWamdYearThreeFuturePatients: 0,
    biosimilarDmeYearOneFuturePatients: 0,
    biosimilarDmeYearTwoFuturePatients: 0,
    biosimilarDmeYearThreeFuturePatients: 0,
    biosimilarPdrYearOneFuturePatients: 0,
    biosimilarPdrYearTwoFuturePatients: 0,
    biosimilarPdrYearThreeFuturePatients: 0,
    biosimilarRvoYearOneFuturePatients: 0,
    biosimilarRvoYearTwoFuturePatients: 0,
    biosimilarRvoYearThreeFuturePatients: 0,
    biosimilarCnvYearOneFuturePatients: 0,
    biosimilarCnvYearTwoFuturePatients: 0,
    biosimilarCnvYearThreeFuturePatients: 0,
  },
  mutations: {
    clear(state) {
      state.drugs = [];
      state.hospital = '';
      state.country = '';
      state.region = '';
      state.organisation = '';
      state.indications = [];
      state.page = 'overview';
      state.patientTypes = [];
      state.population = 0;
      state.manualPopultation = false;
      state.timeframe = 1;
      state.biosimilarPrice = 0;
      state.byovizDiscount = 0;
      state.lucentisDiscount = 0;
      state.biosimilarDiscount = 0;
      state.byovizLocalPrice = 0;
      state.lucentisLocalPrice = 0;
      state.biosimilarLocalPrice = 0;
      state.biosimilarNetCost = 0;
      state.byovizNetCost = 0;
      state.lucentisNetCost = 0;
      state.byovizWamdLoading = 3;
      state.byovizWamdMaintenance = 3;
      state.byovizWamdContinuation = 13;
      state.byovizDmeLoading = 3;
      state.byovizDmeMaintenance = 3;
      state.byovizDmeContinuation = 13;
      state.byovizPdrLoading = 3;
      state.byovizPdrMaintenance = 3;
      state.byovizPdrContinuation = 13;
      state.byovizRvoLoading = 3;
      state.byovizRvoMaintenance = 3;
      state.byovizRvoContinuation = 13;
      state.byovizCnvTotal = 6;
      state.byovizCnvContinuation = 12;
      state.lucentisWamdLoading = 3;
      state.lucentisWamdMaintenance = 3;
      state.lucentisWamdContinuation = 13;
      state.lucentisDmeLoading = 3;
      state.lucentisDmeMaintenance = 3;
      state.lucentisDmeContinuation = 13;
      state.lucentisPdrLoading = 3;
      state.lucentisPdrMaintenance = 3;
      state.lucentisPdrContinuation = 13;
      state.lucentisRvoLoading = 3;
      state.lucentisRvoMaintenance = 3;
      state.lucentisRvoContinuation = 13;
      state.lucentisCnvTotal = 6;
      state.lucentisCnvContinuation = 12;
      state.biosimilarWamdLoading = 3;
      state.biosimilarWamdMaintenance = 3;
      state.biosimilarWamdContinuation = 13;
      state.biosimilarDmeLoading = 3;
      state.biosimilarDmeMaintenance = 3;
      state.biosimilarDmeContinuation = 13;
      state.biosimilarPdrLoading = 3;
      state.biosimilarPdrMaintenance = 3;
      state.biosimilarPdrContinuation = 13;
      state.biosimilarRvoLoading = 3;
      state.biosimilarRvoMaintenance = 3;
      state.biosimilarRvoContinuation = 13;
      state.biosimilarCnvTotal = 6;
      state.biosimilarCnvContinuation = 12;
      state.byovizYearOneWamdDose = 0;
      state.byovizContinuationWamdDose = 0;
      state.byovizYearOneDmeDose = 0;
      state.byovizContinuationDmeDose = 0;
      state.byovizYearOnePdrDose = 0;
      state.byovizContinuationPdrDose = 0;
      state.byovizYearOneRvoDose = 0;
      state.byovizContinuationRvoDose = 0;
      state.byovizYearOneCnvDose = 0;
      state.byovizContinuationCnvDose = 0;
      state.lucentisYearOneWamdDose = 0;
      state.lucentisContinuationWamdDose = 0;
      state.lucentisYearOneDmeDose = 0;
      state.lucentisContinuationDmeDose = 0;
      state.lucentisYearOnePdrDose = 0;
      state.lucentisContinuationPdrDose = 0;
      state.lucentisYearOneRvoDose = 0;
      state.lucentisContinuationRvoDose = 0;
      state.lucentisYearOneCnvDose = 0;
      state.lucentisContinuationCnvDose = 0;
      state.biosimilarYearOneWamdDose = 0;
      state.biosimilarContinuationWamdDose = 0;
      state.biosimilarYearOneDmeDose = 0;
      state.biosimilarContinuationDmeDose = 0;
      state.biosimilarYearOnePdrDose = 0;
      state.biosimilarContinuationPdrDose = 0;
      state.biosimilarYearOneRvoDose = 0;
      state.biosimilarContinuationRvoDose = 0;
      state.biosimilarYearOneCnvDose = 0;
      state.biosimilarContinuationCnvDose = 0;
      state.incidenceOfWamd = 0.0686;
      state.prevalenceOfWamd = 0.505;
      state.incidenceOfDme = 0.0063;
      state.prevalenceOfDme = 0.146;
      state.incidenceOfPdr = 0.007;
      state.prevalenceOfPdr = 0.213;
      state.incidenceOfRvo = 0.163;
      state.prevalenceOfRvo = 0.350;
      state.incidenceOfCnv = 0.005;
      state.prevalenceOfCnv = 0.488;
      state.proportionOfPatientsDrugTreatedWamd = 0;
      state.proportionOfDrugTreatedPatientsReceivingRanibizumabWamd = 0;
      state.proportionOfPatientsDrugTreatedDme = 0;
      state.proportionOfDrugTreatedPatientsReceivingRanibizumabDme = 0;
      state.proportionOfPatientsDrugTreatedPdr = 0;
      state.proportionOfDrugTreatedPatientsReceivingRanibizumabPdr = 0;
      state.proportionOfPatientsDrugTreatedRvo = 0;
      state.proportionOfDrugTreatedPatientsReceivingRanibizumabRvo = 0;
      state.proportionOfPatientsDrugTreatedCnv = 0;
      state.proportionOfDrugTreatedPatientsReceivingRanibizumabCnv = 0;
      state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabWamd = 0;
      state.numberOfPatientsCurrentlyTreatedWithRanibizumabWamd = 0;
      state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabDme = 0;
      state.numberOfPatientsCurrentlyTreatedWithRanibizumabDme = 0;
      state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabPdr = 0;
      state.numberOfPatientsCurrentlyTreatedWithRanibizumabPdr = 0;
      state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabRvo = 0;
      state.numberOfPatientsCurrentlyTreatedWithRanibizumabRvo = 0;
      state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabCnv = 0;
      state.numberOfPatientsCurrentlyTreatedWithRanibizumabCnv = 0;
      state.averageNumberOfEyesAffectedWamd = 1;
      state.averageNumberOfEyesAffectedDme = 1;
      state.averageNumberOfEyesAffectedPdr = 1;
      state.averageNumberOfEyesAffectedRvo = 1;
      state.averageNumberOfEyesAffectedCnv = 1;
      state.byovizYearOneShare = 0;
      state.byovizYearTwoShare = 0;
      state.byovizYearThreeShare = 0;
      state.lucentisYearOneShare = 0;
      state.lucentisYearTwoShare = 0;
      state.lucentisYearThreeShare = 0;
      state.biosimilarYearOneShare = 0;
      state.biosimilarYearTwoShare = 0;
      state.biosimilarYearThreeShare = 0;
      state.byovizYearOneFutureShare = 0;
      state.byovizYearTwoFutureShare = 0;
      state.byovizYearThreeFutureShare = 0;
      state.lucentisYearOneFutureShare = 0;
      state.lucentisYearTwoFutureShare = 0;
      state.lucentisYearThreeFutureShare = 0;
      state.biosimilarYearOneFutureShare = 0;
      state.biosimilarYearTwoFutureShare = 0;
      state.biosimilarYearThreeFutureShare = 0;
      state.yearOneTotalShare = 0;
      state.yearTwoTotalShare = 0;
      state.yearThreeTotalShare = 0;
      state.yearOneTotalShareFuture = 0;
      state.yearTwoTotalShareFuture = 0;
      state.yearThreeTotalShareFuture = 0;
      state.byovizYearOneShareSwitch = 0;
      state.byovizYearTwoShareSwitch = 0;
      state.byovizYearThreeShareSwitch = 0;
      state.lucentisYearOneShareSwitch = 0;
      state.lucentisYearTwoShareSwitch = 0;
      state.lucentisYearThreeShareSwitch = 0;
      state.biosimilarYearOneShareSwitch = 0;
      state.biosimilarYearTwoShareSwitch = 0;
      state.biosimilarYearThreeShareSwitch = 0;
      state.byovizYearOneFutureShareSwitch = 0;
      state.byovizYearTwoFutureShareSwitch = 0;
      state.byovizYearThreeFutureShareSwitch = 0;
      state.lucentisYearOneFutureShareSwitch = 0;
      state.lucentisYearTwoFutureShareSwitch = 0;
      state.lucentisYearThreeFutureShareSwitch = 0;
      state.biosimilarYearOneFutureShareSwitch = 0;
      state.biosimilarYearTwoFutureShareSwitch = 0;
      state.biosimilarYearThreeFutureShareSwitch = 0;
      state.yearOneTotalShareSwitch = 0;
      state.yearTwoTotalShareSwitch = 0;
      state.yearThreeTotalShareSwitch = 0;
      state.yearOneTotalShareFutureSwitch = 0;
      state.yearTwoTotalShareFutureSwitch = 0;
      state.yearThreeTotalShareFutureSwitch = 0;
      state.speedOfImplementation = 0;
    },
    setHash(state, hash) {
      state.hash = hash;
    },
    setId(state, id) {
      state.id = id;
    },
    setPage(state, page) {
      state.page = page;
    },
    setHospital(state, hospital) {
      state.hospital = hospital;
    },
    setPopulation(state, population) {
      state.population = population;
    },
    setPopulationType(state, type) {
      state.manualPopulation = type;
    },
    setCountry(state, country) {
      state.country = country;
    },
    setRegion(state, region) {
      state.region = region;
    },
    setOrganisation(state, organisation) {
      state.organisation = organisation;
    },
    setDrugs(state, drug) {
      /* eslint-disable */
      let drgs = state.drugs;
      drgs.push(drug);
      let drugString = '';
      if (drgs.includes('byoviz')) { drugString += '1'; }
      if (drgs.includes('lucentis')) { drugString += '2'; }
      if (drgs.includes('biosimilars 2')) { drugString += '3'; }
      if (drugString === '1') { drgs = ['byoviz']; }
      if (drugString === '12') { drgs = ['byoviz', 'lucentis']; }
      if (drugString === '123') { drgs = ['byoviz', 'lucentis', 'biosimilars 2']; }
      if (drugString === '2') { drgs = ['lucentis']; }
      if (drugString === '3') { drgs = ['biosimilars 2']; }
      if (drugString === '12') { drgs = ['byoviz', 'lucentis']; }
      if (drugString === '23') { drgs = ['lucentis', 'biosimilars 2']; }
      state.drugs = drgs;
      /* eslint-enable */
    },
    unsetDrugs(state, drug) {
      const index = state.drugs.indexOf(drug);
      if (index !== -1) {
        state.drugs.splice(index, 1);
      }
    },
    setIndication(state, indication) {
      if (indication === 'all') {
        state.indications = ['wamd', 'dme', 'pdr', 'rvo', 'cnv'];
      } else {
        state.indications.push(indication);
      }
    },
    unsetIndication(state, indication) {
      if (indication === 'all') {
        state.indications = [];
      } else {
        const index = state.indications.indexOf(indication);
        if (index !== -1) {
          state.indications.splice(index, 1);
        }
      }
    },
    setTimeframe(state, years) {
      state.timeframe = years;
    },
    setPatientType(state, type) {
      if (type === 'both') {
        state.patientTypes = ['new', 'switch'];
      }
      if (type === 'new') {
        state.patientTypes = ['new'];
      }
      if (type === 'switch') {
        state.patientTypes = ['switch'];
      }
    },
    unsetPatientType(state, type) {
      if (type === 'both') {
        state.patientTypes = [];
      } else {
        const index = state.patientTypes.indexOf(type);
        if (index !== -1) {
          state.patientTypes.splice(index, 1);
        }
      }
    },
    setBiosimilarPrice(state, value) { state.biosimilarPrice = value; },
    setByovizDiscount(state, value) { state.byovizDiscount = value; },
    setLucentisDiscount(state, value) { state.lucentisDiscount = value; },
    setBiosimilarDiscount(state, value) { state.biosimilarDiscount = value; },
    setByovizLocalPrice(state, value) { state.byovizLocalPrice = value; },
    setLucentisLocalPrice(state, value) { state.lucentisLocalPrice = value; },
    setBiosimilarLocalPrice(state, value) { state.biosimilarLocalPrice = value; },
    setBiosimilarNetCost(state, value) { state.biosimilarNetCost = value; },
    setByovizNetCost(state, value) { state.byovizNetCost = value; },
    setLucentisNetCost(state, value) { state.lucentisNetCost = value; },
    setByovizWamdLoading(state, value) { state.byovizWamdLoading = value; },
    setByovizWamdMaintenance(state, value) { state.byovizWamdMaintenance = value; },
    setByovizWamdContinuation(state, value) { state.byovizWamdContinuation = value; },
    setByovizDmeLoading(state, value) { state.byovizDmeLoading = value; },
    setByovizDmeMaintenance(state, value) { state.byovizDmeMaintenance = value; },
    setByovizDmeContinuation(state, value) { state.byovizDmeContinuation = value; },
    setByovizPdrLoading(state, value) { state.byovizPdrLoading = value; },
    setByovizPdrMaintenance(state, value) { state.byovizPdrMaintenance = value; },
    setByovizPdrContinuation(state, value) { state.byovizPdrContinuation = value; },
    setByovizRvoLoading(state, value) { state.byovizRvoLoading = value; },
    setByovizRvoMaintenance(state, value) { state.byovizRvoMaintenance = value; },
    setByovizRvoContinuation(state, value) { state.byovizRvoContinuation = value; },
    setByovizCnvTotal(state, value) { state.byovizCnvTotal = value; },
    setByovizCnvContinuation(state, value) { state.byovizCnvContinuation = value; },
    setLucentisWamdLoading(state, value) { state.lucentisWamdLoading = value; },
    setLucentisWamdMaintenance(state, value) { state.lucentisWamdMaintenance = value; },
    setLucentisWamdContinuation(state, value) { state.lucentisWamdContinuation = value; },
    setLucentisDmeLoading(state, value) { state.lucentisDmeLoading = value; },
    setLucentisDmeMaintenance(state, value) { state.lucentisDmeMaintenance = value; },
    setLucentisDmeContinuation(state, value) { state.lucentisDmeContinuation = value; },
    setLucentisPdrLoading(state, value) { state.lucentisPdrLoading = value; },
    setLucentisPdrMaintenance(state, value) { state.lucentisPdrMaintenance = value; },
    setLucentisPdrContinuation(state, value) { state.lucentisPdrContinuation = value; },
    setLucentisRvoLoading(state, value) { state.lucentisRvoLoading = value; },
    setLucentisRvoMaintenance(state, value) { state.lucentisRvoMaintenance = value; },
    setLucentisRvoContinuation(state, value) { state.lucentisRvoContinuation = value; },
    setLucentisCnvTotal(state, value) { state.lucentisCnvTotal = value; },
    setLucentisCnvContinuation(state, value) { state.lucentisCnvContinuation = value; },
    setBiosimilarWamdLoading(state, value) { state.biosimilarWamdLoading = value; },
    setBiosimilarWamdMaintenance(state, value) { state.biosimilarWamdMaintenance = value; },
    setBiosimilarWamdContinuation(state, value) { state.biosimilarWamdContinuation = value; },
    setBiosimilarDmeLoading(state, value) { state.biosimilarDmeLoading = value; },
    setBiosimilarDmeMaintenance(state, value) { state.biosimilarDmeMaintenance = value; },
    setBiosimilarDmeContinuation(state, value) { state.biosimilarDmeContinuation = value; },
    setBiosimilarPdrLoading(state, value) { state.biosimilarPdrLoading = value; },
    setBiosimilarPdrMaintenance(state, value) { state.biosimilarPdrMaintenance = value; },
    setBiosimilarPdrContinuation(state, value) { state.biosimilarPdrContinuation = value; },
    setBiosimilarRvoLoading(state, value) { state.biosimilarRvoLoading = value; },
    setBiosimilarRvoMaintenance(state, value) { state.biosimilarRvoMaintenance = value; },
    setBiosimilarRvoContinuation(state, value) { state.biosimilarRvoContinuation = value; },
    setBiosimilarCnvTotal(state, value) { state.biosimilarCnvTotal = value; },
    setBiosimilarCnvContinuation(state, value) { state.biosimilarCnvContinuation = value; },
    setByovizYearOneWamd(state, value) { state.byovizYearOneWamdDose = value; },
    setByovizContinuationWamd(state, value) { state.byovizContinuationWamdDose = value; },
    setByovizYearOneDme(state, value) { state.byovizYearOneDmeDose = value; },
    setByovizContinuationDme(state, value) { state.byovizContinuationDmeDose = value; },
    setByovizYearOnePdr(state, value) { state.byovizYearOnePdrDose = value; },
    setByovizContinuationPdr(state, value) { state.byovizContinuationPdrDose = value; },
    setByovizYearOneRvo(state, value) { state.byovizYearOneRvoDose = value; },
    setByovizContinuationRvo(state, value) { state.byovizContinuationRvoDose = value; },
    setByovizYearOneCnv(state, value) { state.byovizYearOneCnvDose = value; },
    setByovizContinuationCnv(state, value) { state.byovizContinuationCnvDose = value; },
    setLucentisYearOneWamd(state, value) { state.lucentisYearOneWamdDose = value; },
    setLucentisContinuationWamd(state, value) { state.lucentisContinuationWamdDose = value; },
    setLucentisYearOneDme(state, value) { state.lucentisYearOneDmeDose = value; },
    setLucentisContinuationDme(state, value) { state.lucentisContinuationDmeDose = value; },
    setLucentisYearOnePdr(state, value) { state.lucentisYearOnePdrDose = value; },
    setLucentisContinuationPdr(state, value) { state.lucentisContinuationPdrDose = value; },
    setLucentisYearOneRvo(state, value) { state.lucentisYearOneRvoDose = value; },
    setLucentisContinuationRvo(state, value) { state.lucentisContinuationRvoDose = value; },
    setLucentisYearOneCnv(state, value) { state.lucentisYearOneCnvDose = value; },
    setLucentisContinuationCnv(state, value) { state.lucentisContinuationCnvDose = value; },
    setBiosimilarYearOneWamd(state, value) { state.biosimilarYearOneWamdDose = value; },
    setBiosimilarContinuationWamd(state, value) { state.biosimilarContinuationWamdDose = value; },
    setBiosimilarYearOneDme(state, value) { state.biosimilarYearOneDmeDose = value; },
    setBiosimilarContinuationDme(state, value) { state.biosimilarContinuationDmeDose = value; },
    setBiosimilarYearOnePdr(state, value) { state.biosimilarYearOnePdrDose = value; },
    setBiosimilarContinuationPdr(state, value) { state.biosimilarContinuationPdrDose = value; },
    setBiosimilarYearOneRvo(state, value) { state.biosimilarYearOneRvoDose = value; },
    setBiosimilarContinuationRvo(state, value) { state.biosimilarContinuationRvoDose = value; },
    setBiosimilarYearOneCnv(state, value) { state.biosimilarYearOneCnvDose = value; },
    setBiosimilarContinuationCnv(state, value) { state.biosimilarContinuationCnvDose = value; },
    updateIncidenceOfWamd(state, value) { state.incidenceOfWamd = value; },
    updatePrevalenceOfWamd(state, value) { state.prevalenceOfWamd = value; },
    updateIncidenceOfDme(state, value) { state.incidenceOfDme = value; },
    updatePrevalenceOfDme(state, value) { state.prevalenceOfDme = value; },
    updateIncidenceOfPdr(state, value) { state.incidenceOfPdr = value; },
    updatePrevalenceOfPdr(state, value) { state.prevalenceOfPdr = value; },
    updateIncidenceOfRvo(state, value) { state.incidenceOfRvo = value; },
    updatePrevalenceOfRvo(state, value) { state.prevalenceOfRvo = value; },
    updateIncidenceOfCnv(state, value) { state.incidenceOfCnv = value; },
    updatePrevalenceOfCnv(state, value) { state.prevalenceOfCnv = value; },
    updateProportionOfPatientsDrugTreatedWamd(state, value) { state.proportionOfPatientsDrugTreatedWamd = value; },
    updateProportionOfDrugTreatedPatientsReceivingRanibizumabWamd(state, value) { state.proportionOfDrugTreatedPatientsReceivingRanibizumabWamd = value; },
    updateProportionOfPatientsDrugTreatedDme(state, value) { state.proportionOfPatientsDrugTreatedDme = value; },
    updateProportionOfDrugTreatedPatientsReceivingRanibizumabDme(state, value) { state.proportionOfDrugTreatedPatientsReceivingRanibizumabDme = value; },
    updateProportionOfPatientsDrugTreatedPdr(state, value) { state.proportionOfPatientsDrugTreatedPdr = value; },
    updateProportionOfDrugTreatedPatientsReceivingRanibizumabPdr(state, value) { state.proportionOfDrugTreatedPatientsReceivingRanibizumabPdr = value; },
    updateProportionOfPatientsDrugTreatedRvo(state, value) { state.proportionOfPatientsDrugTreatedRvo = value; },
    updateProportionOfDrugTreatedPatientsReceivingRanibizumabRvo(state, value) { state.proportionOfDrugTreatedPatientsReceivingRanibizumabRvo = value; },
    updateProportionOfPatientsDrugTreatedCnv(state, value) { state.proportionOfPatientsDrugTreatedCnv = value; },
    updateProportionOfDrugTreatedPatientsReceivingRanibizumabCnv(state, value) { state.proportionOfDrugTreatedPatientsReceivingRanibizumabCnv = value; },
    updateAverageNumberOfEyesAffectedWamd(state, value) { state.averageNumberOfEyesAffectedWamd = value; },
    updateAverageNumberOfEyesAffectedDme(state, value) { state.averageNumberOfEyesAffectedDme = value; },
    updateAverageNumberOfEyesAffectedPdr(state, value) { state.averageNumberOfEyesAffectedPdr = value; },
    updateAverageNumberOfEyesAffectedRvo(state, value) { state.averageNumberOfEyesAffectedRvo = value; },
    updateAverageNumberOfEyesAffectedCnv(state, value) { state.averageNumberOfEyesAffectedCnv = value; },
    setNewTreatedRaniWamd(state, value) { state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabWamd = value; },
    setCurrentTreatedRaniWamd(state, value) { state.numberOfPatientsCurrentlyTreatedWithRanibizumabWamd = value; },
    setNewTreatedRaniDme(state, value) { state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabDme = value; },
    setCurrentTreatedRaniDme(state, value) { state.numberOfPatientsCurrentlyTreatedWithRanibizumabDme = value; },
    setNewTreatedRaniPdr(state, value) { state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabPdr = value; },
    setCurrentTreatedRaniPdr(state, value) { state.numberOfPatientsCurrentlyTreatedWithRanibizumabPdr = value; },
    setNewTreatedRaniRvo(state, value) { state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabRvo = value; },
    setCurrentTreatedRaniRvo(state, value) { state.numberOfPatientsCurrentlyTreatedWithRanibizumabRvo = value; },
    setNewTreatedRaniCnv(state, value) { state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabCnv = value; },
    setCurrentTreatedRaniCnv(state, value) { state.numberOfPatientsCurrentlyTreatedWithRanibizumabCnv = value; },
    updatebyovizYearOneShare(state, value) { state.byovizYearOneShare = value; },
    updatebyovizYearTwoShare(state, value) { state.byovizYearTwoShare = value; },
    updatebyovizYearThreeShare(state, value) { state.byovizYearThreeShare = value; },
    updatelucentisYearOneShare(state, value) { state.lucentisYearOneShare = value; },
    updatelucentisYearTwoShare(state, value) { state.lucentisYearTwoShare = value; },
    updatelucentisYearThreeShare(state, value) { state.lucentisYearThreeShare = value; },
    updatebiosimilarYearOneShare(state, value) { state.biosimilarYearOneShare = value; },
    updatebiosimilarYearTwoShare(state, value) { state.biosimilarYearTwoShare = value; },
    updatebiosimilarYearThreeShare(state, value) { state.biosimilarYearThreeShare = value; },
    updatebyovizYearOneFutureShare(state, value) { state.byovizYearOneFutureShare = value; },
    updatebyovizYearTwoFutureShare(state, value) { state.byovizYearTwoFutureShare = value; },
    updatebyovizYearThreeFutureShare(state, value) { state.byovizYearThreeFutureShare = value; },
    updatelucentisYearOneFutureShare(state, value) { state.lucentisYearOneFutureShare = value; },
    updatelucentisYearTwoFutureShare(state, value) { state.lucentisYearTwoFutureShare = value; },
    updatelucentisYearThreeFutureShare(state, value) { state.lucentisYearThreeFutureShare = value; },
    updatebiosimilarYearOneFutureShare(state, value) { state.biosimilarYearOneFutureShare = value; },
    updatebiosimilarYearTwoFutureShare(state, value) { state.biosimilarYearTwoFutureShare = value; },
    updatebiosimilarYearThreeFutureShare(state, value) { state.biosimilarYearThreeFutureShare = value; },
    updatebyovizYearOneShareSwitch(state, value) { state.byovizYearOneShareSwitch = value; },
    updatebyovizYearTwoShareSwitch(state, value) { state.byovizYearTwoShareSwitch = value; },
    updatebyovizYearThreeShareSwitch(state, value) { state.byovizYearThreeShareSwitch = value; },
    updatelucentisYearOneShareSwitch(state, value) { state.lucentisYearOneShareSwitch = value; },
    updatelucentisYearTwoShareSwitch(state, value) { state.lucentisYearTwoShareSwitch = value; },
    updatelucentisYearThreeShareSwitch(state, value) { state.lucentisYearThreeShareSwitch = value; },
    updatebiosimilarYearOneShareSwitch(state, value) { state.biosimilarYearOneShareSwitch = value; },
    updatebiosimilarYearTwoShareSwitch(state, value) { state.biosimilarYearTwoShareSwitch = value; },
    updatebiosimilarYearThreeShareSwitch(state, value) { state.biosimilarYearThreeShareSwitch = value; },
    updatebyovizYearOneFutureShareSwitch(state, value) {
      state.byovizYearOneFutureShareSwitch = value;
      let numberOfPatients = 0;
      let average = 0;
      //
      numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabWamd, 10) * (parseFloat(state.byovizYearOneFutureShareSwitch, 10) / 100)));
      if (state.speedOfImplementation === 3) { const monthOne = (numberOfPatients / 3); const monthTwo = ((numberOfPatients / 3) * 2); const remainingMonths = (numberOfPatients * 10); const total = (monthOne + monthTwo + remainingMonths); average = Math.round(total / 12); }
      if (state.speedOfImplementation === 6) { const monthOne = (numberOfPatients / 6); const monthTwo = ((numberOfPatients / 6) * 2); const monthThree = ((numberOfPatients / 6) * 3); const monthFour = ((numberOfPatients / 6) * 4); const monthFive = ((numberOfPatients / 6) * 5); const remainingMonths = (numberOfPatients * 7); const total = (monthOne + monthTwo + monthThree + monthFour + monthFive + remainingMonths); average = Math.round(total / 12); }
      if (state.speedOfImplementation === 9) { const monthOne = (numberOfPatients / 9); const monthTwo = ((numberOfPatients / 9) * 2); const monthThree = ((numberOfPatients / 9) * 3); const monthFour = ((numberOfPatients / 9) * 4); const monthFive = ((numberOfPatients / 9) * 5); const monthSix = ((numberOfPatients / 9) * 6); const monthSeven = ((numberOfPatients / 9) * 7); const monthEight = ((numberOfPatients / 9) * 8); const remainingMonths = (numberOfPatients * 4); const total = (monthOne + monthTwo + monthThree + monthFour + monthFive + monthSix + monthSeven + monthEight + remainingMonths); average = Math.round(total / 12); }
      state.byoovizWamdYearOneFuturePatients = average;
      //
      numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabDme, 10) * (parseFloat(state.byovizYearOneFutureShareSwitch, 10) / 100)));
      if (state.speedOfImplementation === 3) { const monthOne = (numberOfPatients / 3); const monthTwo = ((numberOfPatients / 3) * 2); const remainingMonths = (numberOfPatients * 10); const total = (monthOne + monthTwo + remainingMonths); average = Math.round(total / 12); }
      if (state.speedOfImplementation === 6) { const monthOne = (numberOfPatients / 6); const monthTwo = ((numberOfPatients / 6) * 2); const monthThree = ((numberOfPatients / 6) * 3); const monthFour = ((numberOfPatients / 6) * 4); const monthFive = ((numberOfPatients / 6) * 5); const remainingMonths = (numberOfPatients * 7); const total = (monthOne + monthTwo + monthThree + monthFour + monthFive + remainingMonths); average = Math.round(total / 12); }
      if (state.speedOfImplementation === 9) { const monthOne = (numberOfPatients / 9); const monthTwo = ((numberOfPatients / 9) * 2); const monthThree = ((numberOfPatients / 9) * 3); const monthFour = ((numberOfPatients / 9) * 4); const monthFive = ((numberOfPatients / 9) * 5); const monthSix = ((numberOfPatients / 9) * 6); const monthSeven = ((numberOfPatients / 9) * 7); const monthEight = ((numberOfPatients / 9) * 8); const remainingMonths = (numberOfPatients * 4); const total = (monthOne + monthTwo + monthThree + monthFour + monthFive + monthSix + monthSeven + monthEight + remainingMonths); average = Math.round(total / 12); }
      state.byoovizDmeYearOneFuturePatients = average;
      //
      numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabPdr, 10) * (parseFloat(state.byovizYearOneFutureShareSwitch, 10) / 100)));
      if (state.speedOfImplementation === 3) { const monthOne = (numberOfPatients / 3); const monthTwo = ((numberOfPatients / 3) * 2); const remainingMonths = (numberOfPatients * 10); const total = (monthOne + monthTwo + remainingMonths); average = Math.round(total / 12); }
      if (state.speedOfImplementation === 6) { const monthOne = (numberOfPatients / 6); const monthTwo = ((numberOfPatients / 6) * 2); const monthThree = ((numberOfPatients / 6) * 3); const monthFour = ((numberOfPatients / 6) * 4); const monthFive = ((numberOfPatients / 6) * 5); const remainingMonths = (numberOfPatients * 7); const total = (monthOne + monthTwo + monthThree + monthFour + monthFive + remainingMonths); average = Math.round(total / 12); }
      if (state.speedOfImplementation === 9) { const monthOne = (numberOfPatients / 9); const monthTwo = ((numberOfPatients / 9) * 2); const monthThree = ((numberOfPatients / 9) * 3); const monthFour = ((numberOfPatients / 9) * 4); const monthFive = ((numberOfPatients / 9) * 5); const monthSix = ((numberOfPatients / 9) * 6); const monthSeven = ((numberOfPatients / 9) * 7); const monthEight = ((numberOfPatients / 9) * 8); const remainingMonths = (numberOfPatients * 4); const total = (monthOne + monthTwo + monthThree + monthFour + monthFive + monthSix + monthSeven + monthEight + remainingMonths); average = Math.round(total / 12); }
      state.byoovizPdrYearOneFuturePatients = average;
      //
      numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabRvo, 10) * (parseFloat(state.byovizYearOneFutureShareSwitch, 10) / 100)));
      if (state.speedOfImplementation === 3) { const monthOne = (numberOfPatients / 3); const monthTwo = ((numberOfPatients / 3) * 2); const remainingMonths = (numberOfPatients * 10); const total = (monthOne + monthTwo + remainingMonths); average = Math.round(total / 12); }
      if (state.speedOfImplementation === 6) { const monthOne = (numberOfPatients / 6); const monthTwo = ((numberOfPatients / 6) * 2); const monthThree = ((numberOfPatients / 6) * 3); const monthFour = ((numberOfPatients / 6) * 4); const monthFive = ((numberOfPatients / 6) * 5); const remainingMonths = (numberOfPatients * 7); const total = (monthOne + monthTwo + monthThree + monthFour + monthFive + remainingMonths); average = Math.round(total / 12); }
      if (state.speedOfImplementation === 9) { const monthOne = (numberOfPatients / 9); const monthTwo = ((numberOfPatients / 9) * 2); const monthThree = ((numberOfPatients / 9) * 3); const monthFour = ((numberOfPatients / 9) * 4); const monthFive = ((numberOfPatients / 9) * 5); const monthSix = ((numberOfPatients / 9) * 6); const monthSeven = ((numberOfPatients / 9) * 7); const monthEight = ((numberOfPatients / 9) * 8); const remainingMonths = (numberOfPatients * 4); const total = (monthOne + monthTwo + monthThree + monthFour + monthFive + monthSix + monthSeven + monthEight + remainingMonths); average = Math.round(total / 12); }
      state.byoovizRvoYearOneFuturePatients = average;
      //
      numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabCnv, 10) * (parseFloat(state.byovizYearOneFutureShareSwitch, 10) / 100)));
      if (state.speedOfImplementation === 3) { const monthOne = (numberOfPatients / 3); const monthTwo = ((numberOfPatients / 3) * 2); const remainingMonths = (numberOfPatients * 10); const total = (monthOne + monthTwo + remainingMonths); average = Math.round(total / 12); }
      if (state.speedOfImplementation === 6) { const monthOne = (numberOfPatients / 6); const monthTwo = ((numberOfPatients / 6) * 2); const monthThree = ((numberOfPatients / 6) * 3); const monthFour = ((numberOfPatients / 6) * 4); const monthFive = ((numberOfPatients / 6) * 5); const remainingMonths = (numberOfPatients * 7); const total = (monthOne + monthTwo + monthThree + monthFour + monthFive + remainingMonths); average = Math.round(total / 12); }
      if (state.speedOfImplementation === 9) { const monthOne = (numberOfPatients / 9); const monthTwo = ((numberOfPatients / 9) * 2); const monthThree = ((numberOfPatients / 9) * 3); const monthFour = ((numberOfPatients / 9) * 4); const monthFive = ((numberOfPatients / 9) * 5); const monthSix = ((numberOfPatients / 9) * 6); const monthSeven = ((numberOfPatients / 9) * 7); const monthEight = ((numberOfPatients / 9) * 8); const remainingMonths = (numberOfPatients * 4); const total = (monthOne + monthTwo + monthThree + monthFour + monthFive + monthSix + monthSeven + monthEight + remainingMonths); average = Math.round(total / 12); }
      state.byoovizCnvYearOneFuturePatients = average;
    },
    updatebyovizYearTwoFutureShareSwitch(state, value) {
      state.byovizYearTwoFutureShareSwitch = value;
      let numberOfPatients = 0;
      //
      numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabWamd, 10) * (parseFloat(state.byovizYearTwoFutureShareSwitch, 10) / 100)));
      state.byoovizWamdYearTwoFuturePatients = numberOfPatients;
      //
      numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabDme, 10) * (parseFloat(state.byovizYearTwoFutureShareSwitch, 10) / 100)));
      state.byoovizDmeYearTwoFuturePatients = numberOfPatients;
      //
      numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabPdr, 10) * (parseFloat(state.byovizYearTwoFutureShareSwitch, 10) / 100)));
      state.byoovizPdrYearTwoFuturePatients = numberOfPatients;
      //
      numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabRvo, 10) * (parseFloat(state.byovizYearTwoFutureShareSwitch, 10) / 100)));
      state.byoovizRvoYearTwoFuturePatients = numberOfPatients;
      //
      numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabCnv, 10) * (parseFloat(state.byovizYearTwoFutureShareSwitch, 10) / 100)));
      state.byoovizCnvYearTwoFuturePatients = numberOfPatients;
    },
    updatebyovizYearThreeFutureShareSwitch(state, value) {
      state.byovizYearThreeFutureShareSwitch = value;
      let numberOfPatients = 0;
      //
      numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabWamd, 10) * (parseFloat(state.byovizYearThreeFutureShareSwitch, 10) / 100)));
      state.byoovizWamdYearThreeFuturePatients = numberOfPatients;
      //
      numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabDme, 10) * (parseFloat(state.byovizYearThreeFutureShareSwitch, 10) / 100)));
      state.byoovizDmeYearThreeFuturePatients = numberOfPatients;
      //
      numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabPdr, 10) * (parseFloat(state.byovizYearThreeFutureShareSwitch, 10) / 100)));
      state.byoovizPdrYearThreeFuturePatients = numberOfPatients;
      //
      numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabRvo, 10) * (parseFloat(state.byovizYearThreeFutureShareSwitch, 10) / 100)));
      state.byoovizRvoYearThreeFuturePatients = numberOfPatients;
      //
      numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabCnv, 10) * (parseFloat(state.byovizYearThreeFutureShareSwitch, 10) / 100)));
      state.byoovizCnvYearThreeFuturePatients = numberOfPatients;
    },
    updatebiosimilarYearOneFutureShareSwitch(state, value) {
      state.biosimilarYearOneFutureShareSwitch = value;
      let numberOfPatients = 0;
      let average = 0;
      //
      numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabWamd, 10) * (parseFloat(state.biosimilarYearOneFutureShareSwitch, 10) / 100)));
      if (state.speedOfImplementation === 3) { const monthOne = (numberOfPatients / 3); const monthTwo = ((numberOfPatients / 3) * 2); const remainingMonths = (numberOfPatients * 10); const total = (monthOne + monthTwo + remainingMonths); average = Math.round(total / 12); }
      if (state.speedOfImplementation === 6) { const monthOne = (numberOfPatients / 6); const monthTwo = ((numberOfPatients / 6) * 2); const monthThree = ((numberOfPatients / 6) * 3); const monthFour = ((numberOfPatients / 6) * 4); const monthFive = ((numberOfPatients / 6) * 5); const remainingMonths = (numberOfPatients * 7); const total = (monthOne + monthTwo + monthThree + monthFour + monthFive + remainingMonths); average = Math.round(total / 12); }
      if (state.speedOfImplementation === 9) { const monthOne = (numberOfPatients / 9); const monthTwo = ((numberOfPatients / 9) * 2); const monthThree = ((numberOfPatients / 9) * 3); const monthFour = ((numberOfPatients / 9) * 4); const monthFive = ((numberOfPatients / 9) * 5); const monthSix = ((numberOfPatients / 9) * 6); const monthSeven = ((numberOfPatients / 9) * 7); const monthEight = ((numberOfPatients / 9) * 8); const remainingMonths = (numberOfPatients * 4); const total = (monthOne + monthTwo + monthThree + monthFour + monthFive + monthSix + monthSeven + monthEight + remainingMonths); average = Math.round(total / 12); }
      state.biosimilarWamdYearOneFuturePatients = average;
      //
      numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabDme, 10) * (parseFloat(state.biosimilarYearOneFutureShareSwitch, 10) / 100)));
      if (state.speedOfImplementation === 3) { const monthOne = (numberOfPatients / 3); const monthTwo = ((numberOfPatients / 3) * 2); const remainingMonths = (numberOfPatients * 10); const total = (monthOne + monthTwo + remainingMonths); average = Math.round(total / 12); }
      if (state.speedOfImplementation === 6) { const monthOne = (numberOfPatients / 6); const monthTwo = ((numberOfPatients / 6) * 2); const monthThree = ((numberOfPatients / 6) * 3); const monthFour = ((numberOfPatients / 6) * 4); const monthFive = ((numberOfPatients / 6) * 5); const remainingMonths = (numberOfPatients * 7); const total = (monthOne + monthTwo + monthThree + monthFour + monthFive + remainingMonths); average = Math.round(total / 12); }
      if (state.speedOfImplementation === 9) { const monthOne = (numberOfPatients / 9); const monthTwo = ((numberOfPatients / 9) * 2); const monthThree = ((numberOfPatients / 9) * 3); const monthFour = ((numberOfPatients / 9) * 4); const monthFive = ((numberOfPatients / 9) * 5); const monthSix = ((numberOfPatients / 9) * 6); const monthSeven = ((numberOfPatients / 9) * 7); const monthEight = ((numberOfPatients / 9) * 8); const remainingMonths = (numberOfPatients * 4); const total = (monthOne + monthTwo + monthThree + monthFour + monthFive + monthSix + monthSeven + monthEight + remainingMonths); average = Math.round(total / 12); }
      state.biosimilarDmeYearOneFuturePatients = average;
      //
      numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabPdr, 10) * (parseFloat(state.biosimilarYearOneFutureShareSwitch, 10) / 100)));
      if (state.speedOfImplementation === 3) { const monthOne = (numberOfPatients / 3); const monthTwo = ((numberOfPatients / 3) * 2); const remainingMonths = (numberOfPatients * 10); const total = (monthOne + monthTwo + remainingMonths); average = Math.round(total / 12); }
      if (state.speedOfImplementation === 6) { const monthOne = (numberOfPatients / 6); const monthTwo = ((numberOfPatients / 6) * 2); const monthThree = ((numberOfPatients / 6) * 3); const monthFour = ((numberOfPatients / 6) * 4); const monthFive = ((numberOfPatients / 6) * 5); const remainingMonths = (numberOfPatients * 7); const total = (monthOne + monthTwo + monthThree + monthFour + monthFive + remainingMonths); average = Math.round(total / 12); }
      if (state.speedOfImplementation === 9) { const monthOne = (numberOfPatients / 9); const monthTwo = ((numberOfPatients / 9) * 2); const monthThree = ((numberOfPatients / 9) * 3); const monthFour = ((numberOfPatients / 9) * 4); const monthFive = ((numberOfPatients / 9) * 5); const monthSix = ((numberOfPatients / 9) * 6); const monthSeven = ((numberOfPatients / 9) * 7); const monthEight = ((numberOfPatients / 9) * 8); const remainingMonths = (numberOfPatients * 4); const total = (monthOne + monthTwo + monthThree + monthFour + monthFive + monthSix + monthSeven + monthEight + remainingMonths); average = Math.round(total / 12); }
      state.biosimilarPdrYearOneFuturePatients = average;
      //
      numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabRvo, 10) * (parseFloat(state.biosimilarYearOneFutureShareSwitch, 10) / 100)));
      if (state.speedOfImplementation === 3) { const monthOne = (numberOfPatients / 3); const monthTwo = ((numberOfPatients / 3) * 2); const remainingMonths = (numberOfPatients * 10); const total = (monthOne + monthTwo + remainingMonths); average = Math.round(total / 12); }
      if (state.speedOfImplementation === 6) { const monthOne = (numberOfPatients / 6); const monthTwo = ((numberOfPatients / 6) * 2); const monthThree = ((numberOfPatients / 6) * 3); const monthFour = ((numberOfPatients / 6) * 4); const monthFive = ((numberOfPatients / 6) * 5); const remainingMonths = (numberOfPatients * 7); const total = (monthOne + monthTwo + monthThree + monthFour + monthFive + remainingMonths); average = Math.round(total / 12); }
      if (state.speedOfImplementation === 9) { const monthOne = (numberOfPatients / 9); const monthTwo = ((numberOfPatients / 9) * 2); const monthThree = ((numberOfPatients / 9) * 3); const monthFour = ((numberOfPatients / 9) * 4); const monthFive = ((numberOfPatients / 9) * 5); const monthSix = ((numberOfPatients / 9) * 6); const monthSeven = ((numberOfPatients / 9) * 7); const monthEight = ((numberOfPatients / 9) * 8); const remainingMonths = (numberOfPatients * 4); const total = (monthOne + monthTwo + monthThree + monthFour + monthFive + monthSix + monthSeven + monthEight + remainingMonths); average = Math.round(total / 12); }
      state.biosimilarRvoYearOneFuturePatients = average;
      //
      numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabCnv, 10) * (parseFloat(state.biosimilarYearOneFutureShareSwitch, 10) / 100)));
      if (state.speedOfImplementation === 3) { const monthOne = (numberOfPatients / 3); const monthTwo = ((numberOfPatients / 3) * 2); const remainingMonths = (numberOfPatients * 10); const total = (monthOne + monthTwo + remainingMonths); average = Math.round(total / 12); }
      if (state.speedOfImplementation === 6) { const monthOne = (numberOfPatients / 6); const monthTwo = ((numberOfPatients / 6) * 2); const monthThree = ((numberOfPatients / 6) * 3); const monthFour = ((numberOfPatients / 6) * 4); const monthFive = ((numberOfPatients / 6) * 5); const remainingMonths = (numberOfPatients * 7); const total = (monthOne + monthTwo + monthThree + monthFour + monthFive + remainingMonths); average = Math.round(total / 12); }
      if (state.speedOfImplementation === 9) { const monthOne = (numberOfPatients / 9); const monthTwo = ((numberOfPatients / 9) * 2); const monthThree = ((numberOfPatients / 9) * 3); const monthFour = ((numberOfPatients / 9) * 4); const monthFive = ((numberOfPatients / 9) * 5); const monthSix = ((numberOfPatients / 9) * 6); const monthSeven = ((numberOfPatients / 9) * 7); const monthEight = ((numberOfPatients / 9) * 8); const remainingMonths = (numberOfPatients * 4); const total = (monthOne + monthTwo + monthThree + monthFour + monthFive + monthSix + monthSeven + monthEight + remainingMonths); average = Math.round(total / 12); }
      state.biosimilarCnvYearOneFuturePatients = average;
    },
    updatebiosimilarYearTwoFutureShareSwitch(state, value) {
      state.biosimilarYearTwoFutureShareSwitch = value;
      let numberOfPatients = 0;
      //
      numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabWamd, 10) * (parseFloat(state.biosimilarYearTwoFutureShareSwitch, 10) / 100)));
      state.biosimilarWamdYearTwoFuturePatients = numberOfPatients;
      //
      numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabDme, 10) * (parseFloat(state.biosimilarYearTwoFutureShareSwitch, 10) / 100)));
      state.biosimilarDmeYearTwoFuturePatients = numberOfPatients;
      //
      numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabPdr, 10) * (parseFloat(state.biosimilarYearTwoFutureShareSwitch, 10) / 100)));
      state.biosimilarPdrYearTwoFuturePatients = numberOfPatients;
      //
      numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabRvo, 10) * (parseFloat(state.biosimilarYearTwoFutureShareSwitch, 10) / 100)));
      state.biosimilarRvoYearTwoFuturePatients = numberOfPatients;
      //
      numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabCnv, 10) * (parseFloat(state.biosimilarYearTwoFutureShareSwitch, 10) / 100)));
      state.biosimilarCnvYearTwoFuturePatients = numberOfPatients;
    },
    updatebiosimilarYearThreeFutureShareSwitch(state, value) {
      state.biosimilarYearThreeFutureShareSwitch = value;
      let numberOfPatients = 0;
      //
      numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabWamd, 10) * (parseFloat(state.biosimilarYearThreeFutureShareSwitch, 10) / 100)));
      state.biosimilarWamdYearThreeFuturePatients = numberOfPatients;
      //
      numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabDme, 10) * (parseFloat(state.biosimilarYearThreeFutureShareSwitch, 10) / 100)));
      state.biosimilarDmeYearThreeFuturePatients = numberOfPatients;
      //
      numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabPdr, 10) * (parseFloat(state.biosimilarYearThreeFutureShareSwitch, 10) / 100)));
      state.biosimilarPdrYearThreeFuturePatients = numberOfPatients;
      //
      numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabRvo, 10) * (parseFloat(state.biosimilarYearThreeFutureShareSwitch, 10) / 100)));
      state.biosimilarRvoYearThreeFuturePatients = numberOfPatients;
      //
      numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabCnv, 10) * (parseFloat(state.biosimilarYearThreeFutureShareSwitch, 10) / 100)));
      state.biosimilarCnvYearThreeFuturePatients = numberOfPatients;
    },
    updatelucentisYearOneFutureShareSwitch(state, value) { state.lucentisYearOneFutureShareSwitch = value; },
    updatelucentisYearTwoFutureShareSwitch(state, value) { state.lucentisYearTwoFutureShareSwitch = value; },
    updatelucentisYearThreeFutureShareSwitch(state, value) { state.lucentisYearThreeFutureShareSwitch = value; },
    setImplementationSpeed(state, value) { state.speedOfImplementation = value; },
  },
  getters: {
    totalNewPatients(state) {
      return (
        parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabWamd, 10)
        + parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabDme, 10)
        + parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabPdr, 10)
        + parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabRvo, 10)
        + parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabCnv, 10)
      );
    },
    totalSwitchPatients(state) {
      return (
        parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabWamd, 10)
        + parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabDme, 10)
        + parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabPdr, 10)
        + parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabRvo, 10)
        + parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabCnv, 10)
      );
    },
    totalAllPatients(state) {
      return (
        parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabWamd, 10)
        + parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabDme, 10)
        + parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabPdr, 10)
        + parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabRvo, 10)
        + parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabCnv, 10)
        + parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabWamd, 10)
        + parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabDme, 10)
        + parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabPdr, 10)
        + parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabRvo, 10)
        + parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabCnv, 10)
      );
    },
    // Wamd costs current
    newPatientsCurrentCostWamdLucentisYearOne(state) {
      const loadingCost = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisWamdLoading, 10));
      const maintenanceCost = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisWamdMaintenance, 10));
      const totalCostPerPatient = (loadingCost + maintenanceCost);
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabWamd, 10) * (parseFloat(state.lucentisYearOneShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedWamd) * totalCostPerPatient);
      return cost;
    },
    newPatientsCurrentCostWamdByovizYearOne(state) {
      const loadingCost = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizWamdLoading, 10));
      const maintenanceCost = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizWamdMaintenance, 10));
      const totalCostPerPatient = (loadingCost + maintenanceCost);
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabWamd, 10) * (parseFloat(state.byovizYearOneShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedWamd) * totalCostPerPatient);
      return cost;
    },
    newPatientsCurrentCostWamdBiosimilarYearOne(state) {
      const loadingCost = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarWamdLoading, 10));
      const maintenanceCost = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarWamdMaintenance, 10));
      const totalCostPerPatient = (loadingCost + maintenanceCost);
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabWamd, 10) * (parseFloat(state.biosimilarYearOneShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedWamd) * totalCostPerPatient);
      return cost;
    },
    newPatientsCurrentCostWamdLucentisYearTwo(state) {
      const totalCostPerPatient = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisWamdContinuation, 10));
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabWamd, 10) * (parseFloat(state.lucentisYearTwoShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedWamd) * totalCostPerPatient);
      return cost;
    },
    newPatientsCurrentCostWamdByovizYearTwo(state) {
      const totalCostPerPatient = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizWamdContinuation, 10));
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabWamd, 10) * (parseFloat(state.byovizYearTwoShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedWamd) * totalCostPerPatient);
      return cost;
    },
    newPatientsCurrentCostWamdBiosimilarYearTwo(state) {
      const totalCostPerPatient = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarWamdContinuation, 10));
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabWamd, 10) * (parseFloat(state.biosimilarYearTwoShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedWamd) * totalCostPerPatient);
      return cost;
    },
    newPatientsCurrentCostWamdLucentisYearThree(state) {
      const totalCostPerPatient = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisWamdContinuation, 10));
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabWamd, 10) * (parseFloat(state.lucentisYearThreeShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedWamd) * totalCostPerPatient);
      return cost;
    },
    newPatientsCurrentCostWamdByovizYearThree(state) {
      const totalCostPerPatient = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizWamdContinuation, 10));
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabWamd, 10) * (parseFloat(state.byovizYearThreeShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedWamd) * totalCostPerPatient);
      return cost;
    },
    newPatientsCurrentCostWamdBiosimilarYearThree(state) {
      const totalCostPerPatient = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarWamdContinuation, 10));
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabWamd, 10) * (parseFloat(state.biosimilarYearThreeShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedWamd) * totalCostPerPatient);
      return cost;
    },
    // DME costs current
    newPatientsCurrentCostDmeLucentisYearOne(state) {
      const loadingCost = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisDmeLoading, 10));
      const maintenanceCost = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisDmeMaintenance, 10));
      const totalCostPerPatient = (loadingCost + maintenanceCost);
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabDme, 10) * (parseFloat(state.lucentisYearOneShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedDme) * totalCostPerPatient);
      return cost;
    },
    newPatientsCurrentCostDmeByovizYearOne(state) {
      const loadingCost = (parseFloat(state.byovizNetCost, 10) * state.byovizDmeLoading);
      const maintenanceCost = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizDmeMaintenance, 10));
      const totalCostPerPatient = (loadingCost + maintenanceCost);
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabDme, 10) * (parseFloat(state.byovizYearOneShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedDme) * totalCostPerPatient);
      return cost;
    },
    newPatientsCurrentCostDmeBiosimilarYearOne(state) {
      const loadingCost = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarDmeLoading, 10));
      const maintenanceCost = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarDmeMaintenance, 10));
      const totalCostPerPatient = (loadingCost + maintenanceCost);
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabDme, 10) * (parseFloat(state.biosimilarYearOneShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedDme) * totalCostPerPatient);
      return cost;
    },
    newPatientsCurrentCostDmeLucentisYearTwo(state) {
      const totalCostPerPatient = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisDmeContinuation, 10));
      const numberOfPatients = Math.round((state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabDme * (parseFloat(state.lucentisYearTwoShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedDme) * totalCostPerPatient);
      return cost;
    },
    newPatientsCurrentCostDmeByovizYearTwo(state) {
      const totalCostPerPatient = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizDmeContinuation, 10));
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabDme, 10) * (parseFloat(state.byovizYearTwoShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedDme) * totalCostPerPatient);
      return cost;
    },
    newPatientsCurrentCostDmeBiosimilarYearTwo(state) {
      const totalCostPerPatient = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarDmeContinuation, 10));
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabDme, 10) * (parseFloat(state.biosimilarYearTwoShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedDme) * totalCostPerPatient);
      return cost;
    },
    newPatientsCurrentCostDmeLucentisYearThree(state) {
      const totalCostPerPatient = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisDmeContinuation, 10));
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabDme, 10) * (parseFloat(state.lucentisYearThreeShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedDme) * totalCostPerPatient);
      return cost;
    },
    newPatientsCurrentCostDmeByovizYearThree(state) {
      const totalCostPerPatient = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizDmeContinuation, 10));
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabDme, 10) * (parseFloat(state.byovizYearThreeShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedDme) * totalCostPerPatient);
      return cost;
    },
    newPatientsCurrentCostDmeBiosimilarYearThree(state) {
      const totalCostPerPatient = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarDmeContinuation, 10));
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabDme, 10) * (parseFloat(state.biosimilarYearThreeShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedDme) * totalCostPerPatient);
      return cost;
    },
    // PDR costs current
    newPatientsCurrentCostPdrLucentisYearOne(state) {
      const loadingCost = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisPdrLoading, 10));
      const maintenanceCost = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisPdrMaintenance, 10));
      const totalCostPerPatient = (loadingCost + maintenanceCost);
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabPdr, 10) * (parseFloat(state.lucentisYearOneShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedPdr) * totalCostPerPatient);
      return cost;
    },
    newPatientsCurrentCostPdrByovizYearOne(state) {
      const loadingCost = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizPdrLoading, 10));
      const maintenanceCost = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizPdrMaintenance, 10));
      const totalCostPerPatient = (loadingCost + maintenanceCost);
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabPdr, 10) * (parseFloat(state.byovizYearOneShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedPdr) * totalCostPerPatient);
      return cost;
    },
    newPatientsCurrentCostPdrBiosimilarYearOne(state) {
      const loadingCost = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarPdrLoading, 10));
      const maintenanceCost = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarPdrMaintenance, 10));
      const totalCostPerPatient = (loadingCost + maintenanceCost);
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabPdr, 10) * (parseFloat(state.biosimilarYearOneShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedPdr) * totalCostPerPatient);
      return cost;
    },
    newPatientsCurrentCostPdrLucentisYearTwo(state) {
      const totalCostPerPatient = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisPdrContinuation, 10));
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabPdr, 10) * (parseFloat(state.lucentisYearTwoShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedPdr) * totalCostPerPatient);
      return cost;
    },
    newPatientsCurrentCostPdrByovizYearTwo(state) {
      const totalCostPerPatient = (parseFloat(state.byovizNetCost, 10) * state.byovizPdrContinuation);
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabPdr, 10) * (parseFloat(state.byovizYearTwoShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedPdr) * totalCostPerPatient);
      return cost;
    },
    newPatientsCurrentCostPdrBiosimilarYearTwo(state) {
      const totalCostPerPatient = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarPdrContinuation, 10));
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabPdr, 10) * (parseFloat(state.biosimilarYearTwoShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedPdr) * totalCostPerPatient);
      return cost;
    },
    newPatientsCurrentCostPdrLucentisYearThree(state) {
      const totalCostPerPatient = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisPdrContinuation, 10));
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabPdr, 10) * (parseFloat(state.lucentisYearThreeShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedPdr) * totalCostPerPatient);
      return cost;
    },
    newPatientsCurrentCostPdrByovizYearThree(state) {
      const totalCostPerPatient = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizPdrContinuation, 10));
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabPdr, 10) * (parseFloat(state.byovizYearThreeShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedPdr) * totalCostPerPatient);
      return cost;
    },
    newPatientsCurrentCostPdrBiosimilarYearThree(state) {
      const totalCostPerPatient = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarPdrContinuation, 10));
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabPdr, 10) * (parseFloat(state.biosimilarYearThreeShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedPdr) * totalCostPerPatient);
      return cost;
    },
    // RVO costs current
    newPatientsCurrentCostRvoLucentisYearOne(state) {
      const loadingCost = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisRvoLoading, 10));
      const maintenanceCost = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisRvoMaintenance, 10));
      const totalCostPerPatient = (loadingCost + maintenanceCost);
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabRvo, 10) * (parseFloat(state.lucentisYearOneShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedRvo) * totalCostPerPatient);
      return cost;
    },
    newPatientsCurrentCostRvoByovizYearOne(state) {
      const loadingCost = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizRvoLoading, 10));
      const maintenanceCost = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizRvoMaintenance, 10));
      const totalCostPerPatient = (loadingCost + maintenanceCost);
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabRvo, 10) * (parseFloat(state.byovizYearOneShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedRvo) * totalCostPerPatient);
      return cost;
    },
    newPatientsCurrentCostRvoBiosimilarYearOne(state) {
      const loadingCost = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarRvoLoading, 10));
      const maintenanceCost = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarRvoMaintenance, 10));
      const totalCostPerPatient = (loadingCost + maintenanceCost);
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabRvo, 10) * (parseFloat(state.biosimilarYearOneShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedRvo) * totalCostPerPatient);
      return cost;
    },
    newPatientsCurrentCostRvoLucentisYearTwo(state) {
      const totalCostPerPatient = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisRvoContinuation, 10));
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabRvo, 10) * (parseFloat(state.lucentisYearTwoShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedRvo) * totalCostPerPatient);
      return cost;
    },
    newPatientsCurrentCostRvoByovizYearTwo(state) {
      const totalCostPerPatient = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizRvoContinuation, 10));
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabRvo, 10) * (parseFloat(state.byovizYearTwoShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedRvo) * totalCostPerPatient);
      return cost;
    },
    newPatientsCurrentCostRvoBiosimilarYearTwo(state) {
      const totalCostPerPatient = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarRvoContinuation, 10));
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabRvo, 10) * (parseFloat(state.biosimilarYearTwoShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedRvo) * totalCostPerPatient);
      return cost;
    },
    newPatientsCurrentCostRvoLucentisYearThree(state) {
      const totalCostPerPatient = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisRvoContinuation, 10));
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabRvo, 10) * (parseFloat(state.lucentisYearThreeShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedRvo) * totalCostPerPatient);
      return cost;
    },
    newPatientsCurrentCostRvoByovizYearThree(state) {
      const totalCostPerPatient = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizRvoContinuation, 10));
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabRvo, 10) * (parseFloat(state.byovizYearThreeShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedRvo) * totalCostPerPatient);
      return cost;
    },
    newPatientsCurrentCostRvoBiosimilarYearThree(state) {
      const totalCostPerPatient = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarRvoContinuation, 10));
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabRvo, 10) * (parseFloat(state.biosimilarYearThreeShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedRvo) * totalCostPerPatient);
      return cost;
    },
    // CNV costs current
    newPatientsCurrentCostCnvLucentisYearOne(state) {
      const totalCostPerPatient = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisCnvTotal, 10));
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabCnv, 10) * (parseFloat(state.lucentisYearOneShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedCnv) * totalCostPerPatient);
      return cost;
    },
    newPatientsCurrentCostCnvByovizYearOne(state) {
      const totalCostPerPatient = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizCnvTotal, 10));
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabCnv, 10) * (parseFloat(state.byovizYearOneShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedCnv) * totalCostPerPatient);
      return cost;
    },
    newPatientsCurrentCostCnvBiosimilarYearOne(state) {
      const totalCostPerPatient = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarCnvTotal, 10));
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabCnv, 10) * (parseFloat(state.biosimilarYearOneShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedCnv) * totalCostPerPatient);
      return cost;
    },
    newPatientsCurrentCostCnvLucentisYearTwo(state) {
      const totalCostPerPatient = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisCnvContinuation, 10));
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabCnv, 10) * (parseFloat(state.lucentisYearTwoShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedCnv) * totalCostPerPatient);
      return cost;
    },
    newPatientsCurrentCostCnvByovizYearTwo(state) {
      const totalCostPerPatient = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizCnvContinuation, 10));
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabCnv, 10) * (parseFloat(state.byovizYearTwoShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedCnv) * totalCostPerPatient);
      return cost;
    },
    newPatientsCurrentCostCnvBiosimilarYearTwo(state) {
      const totalCostPerPatient = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarCnvContinuation, 10));
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabCnv, 10) * (parseFloat(state.biosimilarYearTwoShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedCnv) * totalCostPerPatient);
      return cost;
    },
    newPatientsCurrentCostCnvLucentisYearThree(state) {
      const totalCostPerPatient = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisCnvContinuation, 10));
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabCnv, 10) * (parseFloat(state.lucentisYearThreeShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedCnv) * totalCostPerPatient);
      return cost;
    },
    newPatientsCurrentCostCnvByovizYearThree(state) {
      const totalCostPerPatient = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizCnvContinuation, 10));
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabCnv, 10) * (parseFloat(state.byovizYearThreeShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedCnv) * totalCostPerPatient);
      return cost;
    },
    newPatientsCurrentCostCnvBiosimilarYearThree(state) {
      const totalCostPerPatient = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarCnvContinuation, 10));
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabCnv, 10) * (parseFloat(state.biosimilarYearThreeShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedCnv) * totalCostPerPatient);
      return cost;
    },
    // Wamd costs future
    newPatientsCurrentCostWamdLucentisYearOneFuture(state) {
      const loadingCost = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisWamdLoading, 10));
      const maintenanceCost = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisWamdMaintenance, 10));
      const totalCostPerPatient = (loadingCost + maintenanceCost);
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabWamd, 10) * (parseFloat(state.lucentisYearOneFutureShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedWamd) * totalCostPerPatient);
      return cost;
    },
    newPatientsWamdLucentisThreeYearFuture(state) {
      const loadingCost = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisWamdLoading, 10));
      const maintenanceCost = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisWamdMaintenance, 10));
      const startingCost = (loadingCost + maintenanceCost);
      const continuationCost = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisWamdContinuation, 10));
      const y1 = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabWamd, 10) * (parseFloat(state.lucentisYearOneFutureShare, 10) / 100)));
      const y2 = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabWamd, 10) * (parseFloat(state.lucentisYearTwoFutureShare, 10) / 100)));
      const y3 = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabWamd, 10) * (parseFloat(state.lucentisYearThreeFutureShare, 10) / 100)));
      const y11Cost = (y1 * startingCost);
      const y21Cost = (y1 * continuationCost);
      const y22Cost = (y2 * startingCost);
      const totalCostY2 = (y21Cost + y22Cost);
      const y1Cost = (y1 * continuationCost);
      const y2Cost = (y2 * continuationCost);
      const y3Cost = (y3 * startingCost);
      const totalCostY3 = (y1Cost + y2Cost + y3Cost);
      const totalCost = (y11Cost + totalCostY2 + totalCostY3);
      const afterEyeAdj = (totalCost * state.averageNumberOfEyesAffectedWamd);
      return afterEyeAdj;
    },
    newPatientsCurrentCostWamdByovizYearOneFuture(state) {
      const loadingCost = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizWamdLoading, 10));
      const maintenanceCost = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizWamdMaintenance, 10));
      const totalCostPerPatient = (loadingCost + maintenanceCost);
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabWamd, 10) * (parseFloat(state.byovizYearOneFutureShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedWamd) * totalCostPerPatient);
      return cost;
    },
    newPatientsWamdByovizThreeYearFuture(state) {
      const loadingCost = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizWamdLoading, 10));
      const maintenanceCost = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizWamdMaintenance, 10));
      const startingCost = (loadingCost + maintenanceCost);
      const continuationCost = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizWamdContinuation, 10));
      const y1 = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabWamd, 10) * (parseFloat(state.byovizYearOneFutureShare, 10) / 100)));
      const y2 = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabWamd, 10) * (parseFloat(state.byovizYearTwoFutureShare, 10) / 100)));
      const y3 = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabWamd, 10) * (parseFloat(state.byovizYearThreeFutureShare, 10) / 100)));
      const y11Cost = (y1 * startingCost);
      const y21Cost = (y1 * continuationCost);
      const y22Cost = (y2 * startingCost);
      const totalCostY2 = (y21Cost + y22Cost);
      const y1Cost = (y1 * continuationCost);
      const y2Cost = (y2 * continuationCost);
      const y3Cost = (y3 * startingCost);
      const totalCostY3 = (y1Cost + y2Cost + y3Cost);
      const totalCost = (y11Cost + totalCostY2 + totalCostY3);
      const afterEyeAdj = (totalCost * state.averageNumberOfEyesAffectedWamd);
      return afterEyeAdj;
    },
    newPatientsCurrentCostWamdBiosimilarYearOneFuture(state) {
      const loadingCost = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarWamdLoading, 10));
      const maintenanceCost = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarWamdMaintenance, 10));
      const totalCostPerPatient = (loadingCost + maintenanceCost);
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabWamd, 10) * (parseFloat(state.biosimilarYearOneFutureShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedWamd) * totalCostPerPatient);
      return cost;
    },
    newPatientsWamdBiosimilarThreeYearFuture(state) {
      const loadingCost = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarWamdLoading, 10));
      const maintenanceCost = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarWamdMaintenance, 10));
      const startingCost = (loadingCost + maintenanceCost);
      const continuationCost = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarWamdContinuation, 10));
      const y1 = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabWamd, 10) * (parseFloat(state.biosimilarYearOneFutureShare, 10) / 100)));
      const y2 = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabWamd, 10) * (parseFloat(state.biosimilarYearTwoFutureShare, 10) / 100)));
      const y3 = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabWamd, 10) * (parseFloat(state.biosimilarYearThreeFutureShare, 10) / 100)));
      const y11Cost = (y1 * startingCost);
      const y21Cost = (y1 * continuationCost);
      const y22Cost = (y2 * startingCost);
      const totalCostY2 = (y21Cost + y22Cost);
      const y1Cost = (y1 * continuationCost);
      const y2Cost = (y2 * continuationCost);
      const y3Cost = (y3 * startingCost);
      const totalCostY3 = (y1Cost + y2Cost + y3Cost);
      const totalCost = (y11Cost + totalCostY2 + totalCostY3);
      const afterEyeAdj = (totalCost * state.averageNumberOfEyesAffectedWamd);
      return afterEyeAdj;
    },
    // DME costs future
    newPatientsCurrentCostDmeLucentisYearOneFuture(state) {
      const loadingCost = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisDmeLoading, 10));
      const maintenanceCost = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisDmeMaintenance, 10));
      const totalCostPerPatient = (loadingCost + maintenanceCost);
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabDme, 10) * (parseFloat(state.lucentisYearOneFutureShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedDme) * totalCostPerPatient);
      return cost;
    },
    newPatientsDmeLucentisThreeYearFuture(state) {
      const loadingCost = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisDmeLoading, 10));
      const maintenanceCost = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisDmeMaintenance, 10));
      const startingCost = (loadingCost + maintenanceCost);
      const continuationCost = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisDmeContinuation, 10));
      const y1 = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabDme, 10) * (parseFloat(state.lucentisYearOneFutureShare, 10) / 100)));
      const y2 = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabDme, 10) * (parseFloat(state.lucentisYearTwoFutureShare, 10) / 100)));
      const y3 = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabDme, 10) * (parseFloat(state.lucentisYearThreeFutureShare, 10) / 100)));
      const y11Cost = (y1 * startingCost);
      const y21Cost = (y1 * continuationCost);
      const y22Cost = (y2 * startingCost);
      const totalCostY2 = (y21Cost + y22Cost);
      const y1Cost = (y1 * continuationCost);
      const y2Cost = (y2 * continuationCost);
      const y3Cost = (y3 * startingCost);
      const totalCostY3 = (y1Cost + y2Cost + y3Cost);
      const totalCost = (y11Cost + totalCostY2 + totalCostY3);
      const afterEyeAdj = (totalCost * state.averageNumberOfEyesAffectedDme);
      return afterEyeAdj;
    },
    newPatientsCurrentCostDmeByovizYearOneFuture(state) {
      const loadingCost = (parseFloat(state.byovizNetCost, 10) * state.byovizDmeLoading);
      const maintenanceCost = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizDmeMaintenance, 10));
      const totalCostPerPatient = (loadingCost + maintenanceCost);
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabDme, 10) * (parseFloat(state.byovizYearOneFutureShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedDme) * totalCostPerPatient);
      return cost;
    },
    newPatientsDmeByovizThreeYearFuture(state) {
      const loadingCost = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizDmeLoading, 10));
      const maintenanceCost = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizDmeMaintenance, 10));
      const startingCost = (loadingCost + maintenanceCost);
      const continuationCost = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizDmeContinuation, 10));
      const y1 = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabDme, 10) * (parseFloat(state.byovizYearOneFutureShare, 10) / 100)));
      const y2 = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabDme, 10) * (parseFloat(state.byovizYearTwoFutureShare, 10) / 100)));
      const y3 = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabDme, 10) * (parseFloat(state.byovizYearThreeFutureShare, 10) / 100)));
      const y11Cost = (y1 * startingCost);
      const y21Cost = (y1 * continuationCost);
      const y22Cost = (y2 * startingCost);
      const totalCostY2 = (y21Cost + y22Cost);
      const y1Cost = (y1 * continuationCost);
      const y2Cost = (y2 * continuationCost);
      const y3Cost = (y3 * startingCost);
      const totalCostY3 = (y1Cost + y2Cost + y3Cost);
      const totalCost = (y11Cost + totalCostY2 + totalCostY3);
      const afterEyeAdj = (totalCost * state.averageNumberOfEyesAffectedDme);
      return afterEyeAdj;
    },
    newPatientsCurrentCostDmeBiosimilarYearOneFuture(state) {
      const loadingCost = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarDmeLoading, 10));
      const maintenanceCost = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarDmeMaintenance, 10));
      const totalCostPerPatient = (loadingCost + maintenanceCost);
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabDme, 10) * (parseFloat(state.biosimilarYearOneFutureShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedDme) * totalCostPerPatient);
      return cost;
    },
    newPatientsDmeBiosimilarThreeYearFuture(state) {
      const loadingCost = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarDmeLoading, 10));
      const maintenanceCost = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarDmeMaintenance, 10));
      const startingCost = (loadingCost + maintenanceCost);
      const continuationCost = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarDmeContinuation, 10));
      const y1 = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabDme, 10) * (parseFloat(state.biosimilarYearOneFutureShare, 10) / 100)));
      const y2 = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabDme, 10) * (parseFloat(state.biosimilarYearTwoFutureShare, 10) / 100)));
      const y3 = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabDme, 10) * (parseFloat(state.biosimilarYearThreeFutureShare, 10) / 100)));
      const y11Cost = (y1 * startingCost);
      const y21Cost = (y1 * continuationCost);
      const y22Cost = (y2 * startingCost);
      const totalCostY2 = (y21Cost + y22Cost);
      const y1Cost = (y1 * continuationCost);
      const y2Cost = (y2 * continuationCost);
      const y3Cost = (y3 * startingCost);
      const totalCostY3 = (y1Cost + y2Cost + y3Cost);
      const totalCost = (y11Cost + totalCostY2 + totalCostY3);
      const afterEyeAdj = (totalCost * state.averageNumberOfEyesAffectedDme);
      return afterEyeAdj;
    },
    // PDR costs future
    newPatientsCurrentCostPdrLucentisYearOneFuture(state) {
      const loadingCost = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisPdrLoading, 10));
      const maintenanceCost = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisPdrMaintenance, 10));
      const totalCostPerPatient = (loadingCost + maintenanceCost);
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabPdr, 10) * (parseFloat(state.lucentisYearOneFutureShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedPdr) * totalCostPerPatient);
      return cost;
    },
    newPatientsPdrLucentisThreeYearFuture(state) {
      const loadingCost = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisPdrLoading, 10));
      const maintenanceCost = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisPdrMaintenance, 10));
      const startingCost = (loadingCost + maintenanceCost);
      const continuationCost = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisPdrContinuation, 10));
      const y1 = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabPdr, 10) * (parseFloat(state.lucentisYearOneFutureShare, 10) / 100)));
      const y2 = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabPdr, 10) * (parseFloat(state.lucentisYearTwoFutureShare, 10) / 100)));
      const y3 = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabPdr, 10) * (parseFloat(state.lucentisYearThreeFutureShare, 10) / 100)));
      const y11Cost = (y1 * startingCost);
      const y21Cost = (y1 * continuationCost);
      const y22Cost = (y2 * startingCost);
      const totalCostY2 = (y21Cost + y22Cost);
      const y1Cost = (y1 * continuationCost);
      const y2Cost = (y2 * continuationCost);
      const y3Cost = (y3 * startingCost);
      const totalCostY3 = (y1Cost + y2Cost + y3Cost);
      const totalCost = (y11Cost + totalCostY2 + totalCostY3);
      const afterEyeAdj = (totalCost * state.averageNumberOfEyesAffectedPdr);
      return afterEyeAdj;
    },
    newPatientsCurrentCostPdrByovizYearOneFuture(state) {
      const loadingCost = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizPdrLoading, 10));
      const maintenanceCost = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizPdrMaintenance, 10));
      const totalCostPerPatient = (loadingCost + maintenanceCost);
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabPdr, 10) * (parseFloat(state.byovizYearOneFutureShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedPdr) * totalCostPerPatient);
      return cost;
    },
    newPatientsPdrByovizThreeYearFuture(state) {
      const loadingCost = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizPdrLoading, 10));
      const maintenanceCost = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizPdrMaintenance, 10));
      const startingCost = (loadingCost + maintenanceCost);
      const continuationCost = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizPdrContinuation, 10));
      const y1 = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabPdr, 10) * (parseFloat(state.byovizYearOneFutureShare, 10) / 100)));
      const y2 = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabPdr, 10) * (parseFloat(state.byovizYearTwoFutureShare, 10) / 100)));
      const y3 = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabPdr, 10) * (parseFloat(state.byovizYearThreeFutureShare, 10) / 100)));
      const y11Cost = (y1 * startingCost);
      const y21Cost = (y1 * continuationCost);
      const y22Cost = (y2 * startingCost);
      const totalCostY2 = (y21Cost + y22Cost);
      const y1Cost = (y1 * continuationCost);
      const y2Cost = (y2 * continuationCost);
      const y3Cost = (y3 * startingCost);
      const totalCostY3 = (y1Cost + y2Cost + y3Cost);
      const totalCost = (y11Cost + totalCostY2 + totalCostY3);
      const afterEyeAdj = (totalCost * state.averageNumberOfEyesAffectedPdr);
      return afterEyeAdj;
    },
    newPatientsCurrentCostPdrBiosimilarYearOneFuture(state) {
      const loadingCost = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarPdrLoading, 10));
      const maintenanceCost = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarPdrMaintenance, 10));
      const totalCostPerPatient = (loadingCost + maintenanceCost);
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabPdr, 10) * (parseFloat(state.biosimilarYearOneFutureShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedPdr) * totalCostPerPatient);
      return cost;
    },
    newPatientsPdrBiosimilarThreeYearFuture(state) {
      const loadingCost = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarPdrLoading, 10));
      const maintenanceCost = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarPdrMaintenance, 10));
      const startingCost = (loadingCost + maintenanceCost);
      const continuationCost = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarPdrContinuation, 10));
      const y1 = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabPdr, 10) * (parseFloat(state.biosimilarYearOneFutureShare, 10) / 100)));
      const y2 = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabPdr, 10) * (parseFloat(state.biosimilarYearTwoFutureShare, 10) / 100)));
      const y3 = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabPdr, 10) * (parseFloat(state.biosimilarYearThreeFutureShare, 10) / 100)));
      const y11Cost = (y1 * startingCost);
      const y21Cost = (y1 * continuationCost);
      const y22Cost = (y2 * startingCost);
      const totalCostY2 = (y21Cost + y22Cost);
      const y1Cost = (y1 * continuationCost);
      const y2Cost = (y2 * continuationCost);
      const y3Cost = (y3 * startingCost);
      const totalCostY3 = (y1Cost + y2Cost + y3Cost);
      const totalCost = (y11Cost + totalCostY2 + totalCostY3);
      const afterEyeAdj = (totalCost * state.averageNumberOfEyesAffectedPdr);
      return afterEyeAdj;
    },
    // RVO costs future
    newPatientsCurrentCostRvoLucentisYearOneFuture(state) {
      const loadingCost = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisRvoLoading, 10));
      const maintenanceCost = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisRvoMaintenance, 10));
      const totalCostPerPatient = (loadingCost + maintenanceCost);
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabRvo, 10) * (parseFloat(state.lucentisYearOneFutureShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedRvo) * totalCostPerPatient);
      return cost;
    },
    newPatientsRvoLucentisThreeYearFuture(state) {
      const loadingCost = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisRvoLoading, 10));
      const maintenanceCost = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisRvoMaintenance, 10));
      const startingCost = (loadingCost + maintenanceCost);
      const continuationCost = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisRvoContinuation, 10));
      const y1 = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabRvo, 10) * (parseFloat(state.lucentisYearOneFutureShare, 10) / 100)));
      const y2 = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabRvo, 10) * (parseFloat(state.lucentisYearTwoFutureShare, 10) / 100)));
      const y3 = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabRvo, 10) * (parseFloat(state.lucentisYearThreeFutureShare, 10) / 100)));
      const y11Cost = (y1 * startingCost);
      const y21Cost = (y1 * continuationCost);
      const y22Cost = (y2 * startingCost);
      const totalCostY2 = (y21Cost + y22Cost);
      const y1Cost = (y1 * continuationCost);
      const y2Cost = (y2 * continuationCost);
      const y3Cost = (y3 * startingCost);
      const totalCostY3 = (y1Cost + y2Cost + y3Cost);
      const totalCost = (y11Cost + totalCostY2 + totalCostY3);
      const afterEyeAdj = (totalCost * state.averageNumberOfEyesAffectedRvo);
      return afterEyeAdj;
    },
    newPatientsCurrentCostRvoByovizYearOneFuture(state) {
      const loadingCost = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizRvoLoading, 10));
      const maintenanceCost = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizRvoMaintenance, 10));
      const totalCostPerPatient = (loadingCost + maintenanceCost);
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabRvo, 10) * (parseFloat(state.byovizYearOneFutureShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedRvo) * totalCostPerPatient);
      return cost;
    },
    newPatientsRvoByovizThreeYearFuture(state) {
      const loadingCost = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizRvoLoading, 10));
      const maintenanceCost = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizRvoMaintenance, 10));
      const startingCost = (loadingCost + maintenanceCost);
      const continuationCost = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizRvoContinuation, 10));
      const y1 = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabRvo, 10) * (parseFloat(state.byovizYearOneFutureShare, 10) / 100)));
      const y2 = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabRvo, 10) * (parseFloat(state.byovizYearTwoFutureShare, 10) / 100)));
      const y3 = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabRvo, 10) * (parseFloat(state.byovizYearThreeFutureShare, 10) / 100)));
      const y11Cost = (y1 * startingCost);
      const y21Cost = (y1 * continuationCost);
      const y22Cost = (y2 * startingCost);
      const totalCostY2 = (y21Cost + y22Cost);
      const y1Cost = (y1 * continuationCost);
      const y2Cost = (y2 * continuationCost);
      const y3Cost = (y3 * startingCost);
      const totalCostY3 = (y1Cost + y2Cost + y3Cost);
      const totalCost = (y11Cost + totalCostY2 + totalCostY3);
      const afterEyeAdj = (totalCost * state.averageNumberOfEyesAffectedRvo);
      return afterEyeAdj;
    },
    newPatientsCurrentCostRvoBiosimilarYearOneFuture(state) {
      const loadingCost = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarRvoLoading, 10));
      const maintenanceCost = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarRvoMaintenance, 10));
      const totalCostPerPatient = (loadingCost + maintenanceCost);
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabRvo, 10) * (parseFloat(state.biosimilarYearOneFutureShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedRvo) * totalCostPerPatient);
      return cost;
    },
    newPatientsRvoBiosimilarThreeYearFuture(state) {
      const loadingCost = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarRvoLoading, 10));
      const maintenanceCost = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarRvoMaintenance, 10));
      const startingCost = (loadingCost + maintenanceCost);
      const continuationCost = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarRvoContinuation, 10));
      const y1 = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabRvo, 10) * (parseFloat(state.biosimilarYearOneFutureShare, 10) / 100)));
      const y2 = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabRvo, 10) * (parseFloat(state.biosimilarYearTwoFutureShare, 10) / 100)));
      const y3 = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabRvo, 10) * (parseFloat(state.biosimilarYearThreeFutureShare, 10) / 100)));
      const y11Cost = (y1 * startingCost);
      const y21Cost = (y1 * continuationCost);
      const y22Cost = (y2 * startingCost);
      const totalCostY2 = (y21Cost + y22Cost);
      const y1Cost = (y1 * continuationCost);
      const y2Cost = (y2 * continuationCost);
      const y3Cost = (y3 * startingCost);
      const totalCostY3 = (y1Cost + y2Cost + y3Cost);
      const totalCost = (y11Cost + totalCostY2 + totalCostY3);
      const afterEyeAdj = (totalCost * state.averageNumberOfEyesAffectedRvo);
      return afterEyeAdj;
    },
    // CNV costs future
    newPatientsCurrentCostCnvLucentisYearOneFuture(state) {
      const totalCostPerPatient = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisCnvTotal, 10));
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabCnv, 10) * (parseFloat(state.lucentisYearOneFutureShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedCnv) * totalCostPerPatient);
      return cost;
    },
    newPatientsCnvLucentisThreeYearFuture(state) {
      const startingCost = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisCnvTotal, 10));
      const continuationCost = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisCnvContinuation, 10));
      const y1 = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabCnv, 10) * (parseFloat(state.lucentisYearOneFutureShare, 10) / 100)));
      const y2 = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabCnv, 10) * (parseFloat(state.lucentisYearTwoFutureShare, 10) / 100)));
      const y3 = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabCnv, 10) * (parseFloat(state.lucentisYearThreeFutureShare, 10) / 100)));
      const y11Cost = (y1 * startingCost);
      const y21Cost = (y1 * continuationCost);
      const y22Cost = (y2 * startingCost);
      const totalCostY2 = (y21Cost + y22Cost);
      const y1Cost = (y1 * continuationCost);
      const y2Cost = (y2 * continuationCost);
      const y3Cost = (y3 * startingCost);
      const totalCostY3 = (y1Cost + y2Cost + y3Cost);
      const totalCost = (y11Cost + totalCostY2 + totalCostY3);
      const afterEyeAdj = (totalCost * state.averageNumberOfEyesAffectedCnv);
      return afterEyeAdj;
    },
    newPatientsCurrentCostCnvByovizYearOneFuture(state) {
      const totalCostPerPatient = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizCnvTotal, 10));
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabCnv, 10) * (parseFloat(state.byovizYearOneFutureShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedCnv) * totalCostPerPatient);
      return cost;
    },
    newPatientsCnvByovizThreeYearFuture(state) {
      const startingCost = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizCnvTotal, 10));
      const continuationCost = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizCnvContinuation, 10));
      const y1 = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabCnv, 10) * (parseFloat(state.byovizYearOneFutureShare, 10) / 100)));
      const y2 = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabCnv, 10) * (parseFloat(state.byovizYearTwoFutureShare, 10) / 100)));
      const y3 = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabCnv, 10) * (parseFloat(state.byovizYearThreeFutureShare, 10) / 100)));
      const y11Cost = (y1 * startingCost);
      const y21Cost = (y1 * continuationCost);
      const y22Cost = (y2 * startingCost);
      const totalCostY2 = (y21Cost + y22Cost);
      const y1Cost = (y1 * continuationCost);
      const y2Cost = (y2 * continuationCost);
      const y3Cost = (y3 * startingCost);
      const totalCostY3 = (y1Cost + y2Cost + y3Cost);
      const totalCost = (y11Cost + totalCostY2 + totalCostY3);
      const afterEyeAdj = (totalCost * state.averageNumberOfEyesAffectedCnv);
      return afterEyeAdj;
    },
    newPatientsCurrentCostCnvBiosimilarYearOneFuture(state) {
      const totalCostPerPatient = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarCnvTotal, 10));
      const numberOfPatients = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabCnv, 10) * (parseFloat(state.biosimilarYearOneFutureShare, 10) / 100)));
      const cost = ((numberOfPatients * state.averageNumberOfEyesAffectedCnv) * totalCostPerPatient);
      return cost;
    },
    newPatientsCnvBiosimilarThreeYearFuture(state) {
      const startingCost = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarCnvTotal, 10));
      const continuationCost = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarCnvContinuation, 10));
      const y1 = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabCnv, 10) * (parseFloat(state.biosimilarYearOneFutureShare, 10) / 100)));
      const y2 = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabCnv, 10) * (parseFloat(state.biosimilarYearTwoFutureShare, 10) / 100)));
      const y3 = Math.round((parseInt(state.numberOfNewPatientsCurrentlyTreatedWithRanibizumabCnv, 10) * (parseFloat(state.biosimilarYearThreeFutureShare, 10) / 100)));
      const y11Cost = (y1 * startingCost);
      const y21Cost = (y1 * continuationCost);
      const y22Cost = (y2 * startingCost);
      const totalCostY2 = (y21Cost + y22Cost);
      const y1Cost = (y1 * continuationCost);
      const y2Cost = (y2 * continuationCost);
      const y3Cost = (y3 * startingCost);
      const totalCostY3 = (y1Cost + y2Cost + y3Cost);
      const totalCost = (y11Cost + totalCostY2 + totalCostY3);
      const afterEyeAdj = (totalCost * state.averageNumberOfEyesAffectedCnv);
      return afterEyeAdj;
    },
    // Wamd costs current switch
    switchPatientsCurrentCostWamdLucentisYearOne(state) {
      const totalCostPerPatient = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisWamdContinuation, 10));
      let numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabWamd, 10) * (parseFloat(state.lucentisYearOneShareSwitch, 10) / 100)));
      numberOfPatients *= state.averageNumberOfEyesAffectedWamd;
      return (numberOfPatients * totalCostPerPatient);
    },
    switchPatientsCurrentCostWamdByovizYearOne(state) {
      const totalCostPerPatient = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizWamdContinuation, 10));
      let numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabWamd, 10) * (parseFloat(state.byovizYearOneShareSwitch, 10) / 100)));
      numberOfPatients *= state.averageNumberOfEyesAffectedWamd;
      return (numberOfPatients * totalCostPerPatient);
    },
    switchPatientsCurrentCostWamdBiosimilarYearOne(state) {
      const totalCostPerPatient = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarWamdContinuation, 10));
      let numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabWamd, 10) * (parseFloat(state.biosimilarYearOneShareSwitch, 10) / 100)));
      numberOfPatients *= state.averageNumberOfEyesAffectedWamd;
      return (numberOfPatients * totalCostPerPatient);
    },
    switchPatientsCurrentCostWamdLucentisYearTwo(state) {
      const totalCostPerPatient = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisWamdContinuation, 10));
      let numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabWamd, 10) * (parseFloat(state.lucentisYearTwoShareSwitch, 10) / 100)));
      numberOfPatients *= state.averageNumberOfEyesAffectedWamd;
      return (numberOfPatients * totalCostPerPatient);
    },
    switchPatientsCurrentCostWamdByovizYearTwo(state) {
      const totalCostPerPatient = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizWamdContinuation, 10));
      let numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabWamd, 10) * (parseFloat(state.byovizYearTwoShareSwitch, 10) / 100)));
      numberOfPatients *= state.averageNumberOfEyesAffectedWamd;
      return (numberOfPatients * totalCostPerPatient);
    },
    switchPatientsCurrentCostWamdBiosimilarYearTwo(state) {
      const totalCostPerPatient = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarWamdContinuation, 10));
      let numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabWamd, 10) * (parseFloat(state.biosimilarYearTwoShareSwitch, 10) / 100)));
      numberOfPatients *= state.averageNumberOfEyesAffectedWamd;
      return (numberOfPatients * totalCostPerPatient);
    },
    switchPatientsCurrentCostWamdLucentisYearThree(state) {
      const totalCostPerPatient = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisWamdContinuation, 10));
      let numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabWamd, 10) * (parseFloat(state.lucentisYearThreeShareSwitch, 10) / 100)));
      numberOfPatients *= state.averageNumberOfEyesAffectedWamd;
      return (numberOfPatients * totalCostPerPatient);
    },
    switchPatientsCurrentCostWamdByovizYearThree(state) {
      const totalCostPerPatient = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizWamdContinuation, 10));
      let numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabWamd, 10) * (parseFloat(state.byovizYearThreeShareSwitch, 10) / 100)));
      numberOfPatients *= state.averageNumberOfEyesAffectedWamd;
      return (numberOfPatients * totalCostPerPatient);
    },
    switchPatientsCurrentCostWamdBiosimilarYearThree(state) {
      const totalCostPerPatient = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarWamdContinuation, 10));
      let numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabWamd, 10) * (parseFloat(state.biosimilarYearThreeShareSwitch, 10) / 100)));
      numberOfPatients *= state.averageNumberOfEyesAffectedWamd;
      return (numberOfPatients * totalCostPerPatient);
    },
    // DME costs current switch
    switchPatientsCurrentCostDmeLucentisYearOne(state) {
      const totalCostPerPatient = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisDmeContinuation, 10));
      let numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabDme, 10) * (parseFloat(state.lucentisYearOneShareSwitch, 10) / 100)));
      numberOfPatients *= state.averageNumberOfEyesAffectedDme;
      return (numberOfPatients * totalCostPerPatient);
    },
    switchPatientsCurrentCostDmeByovizYearOne(state) {
      const totalCostPerPatient = (parseFloat(state.byovizNetCost, 10) * state.byovizDmeContinuation);
      let numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabDme, 10) * (parseFloat(state.byovizYearOneShareSwitch, 10) / 100)));
      numberOfPatients *= state.averageNumberOfEyesAffectedDme;
      return (numberOfPatients * totalCostPerPatient);
    },
    switchPatientsCurrentCostDmeBiosimilarYearOne(state) {
      const totalCostPerPatient = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarDmeContinuation, 10));
      let numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabDme, 10) * (parseFloat(state.biosimilarYearOneShareSwitch, 10) / 100)));
      numberOfPatients *= state.averageNumberOfEyesAffectedDme;
      return (numberOfPatients * totalCostPerPatient);
    },
    switchPatientsCurrentCostDmeLucentisYearTwo(state) {
      const totalCostPerPatient = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisDmeContinuation, 10));
      let numberOfPatients = Math.round((state.numberOfPatientsCurrentlyTreatedWithRanibizumabDme * (parseFloat(state.lucentisYearTwoShareSwitch, 10) / 100)));
      numberOfPatients *= state.averageNumberOfEyesAffectedDme;
      return (numberOfPatients * totalCostPerPatient);
    },
    switchPatientsCurrentCostDmeByovizYearTwo(state) {
      const totalCostPerPatient = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizDmeContinuation, 10));
      let numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabDme, 10) * (parseFloat(state.byovizYearTwoShareSwitch, 10) / 100)));
      numberOfPatients *= state.averageNumberOfEyesAffectedDme;
      return (numberOfPatients * totalCostPerPatient);
    },
    switchPatientsCurrentCostDmeBiosimilarYearTwo(state) {
      const totalCostPerPatient = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarDmeContinuation, 10));
      let numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabDme, 10) * (parseFloat(state.biosimilarYearTwoShareSwitch, 10) / 100)));
      numberOfPatients *= state.averageNumberOfEyesAffectedDme;
      return (numberOfPatients * totalCostPerPatient);
    },
    switchPatientsCurrentCostDmeLucentisYearThree(state) {
      const totalCostPerPatient = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisDmeContinuation, 10));
      let numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabDme, 10) * (parseFloat(state.lucentisYearThreeShareSwitch, 10) / 100)));
      numberOfPatients *= state.averageNumberOfEyesAffectedDme;
      return (numberOfPatients * totalCostPerPatient);
    },
    switchPatientsCurrentCostDmeByovizYearThree(state) {
      const totalCostPerPatient = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizDmeContinuation, 10));
      let numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabDme, 10) * (parseFloat(state.byovizYearThreeShareSwitch, 10) / 100)));
      numberOfPatients *= state.averageNumberOfEyesAffectedDme;
      return (numberOfPatients * totalCostPerPatient);
    },
    switchPatientsCurrentCostDmeBiosimilarYearThree(state) {
      const totalCostPerPatient = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarDmeContinuation, 10));
      let numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabDme, 10) * (parseFloat(state.biosimilarYearThreeShareSwitch, 10) / 100)));
      numberOfPatients *= state.averageNumberOfEyesAffectedDme;
      return (numberOfPatients * totalCostPerPatient);
    },
    // PDR costs current switch
    switchPatientsCurrentCostPdrLucentisYearOne(state) {
      const totalCostPerPatient = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisPdrContinuation, 10));
      let numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabPdr, 10) * (parseFloat(state.lucentisYearOneShareSwitch, 10) / 100)));
      numberOfPatients *= state.averageNumberOfEyesAffectedPdr;
      return (numberOfPatients * totalCostPerPatient);
    },
    switchPatientsCurrentCostPdrByovizYearOne(state) {
      const totalCostPerPatient = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizPdrContinuation, 10));
      let numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabPdr, 10) * (parseFloat(state.byovizYearOneShareSwitch, 10) / 100)));
      numberOfPatients *= state.averageNumberOfEyesAffectedPdr;
      return (numberOfPatients * totalCostPerPatient);
    },
    switchPatientsCurrentCostPdrBiosimilarYearOne(state) {
      const totalCostPerPatient = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarPdrContinuation, 10));
      let numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabPdr, 10) * (parseFloat(state.biosimilarYearOneShareSwitch, 10) / 100)));
      numberOfPatients *= state.averageNumberOfEyesAffectedPdr;
      return (numberOfPatients * totalCostPerPatient);
    },
    switchPatientsCurrentCostPdrLucentisYearTwo(state) {
      const totalCostPerPatient = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisPdrContinuation, 10));
      let numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabPdr, 10) * (parseFloat(state.lucentisYearTwoShareSwitch, 10) / 100)));
      numberOfPatients *= state.averageNumberOfEyesAffectedPdr;
      return (numberOfPatients * totalCostPerPatient);
    },
    switchPatientsCurrentCostPdrByovizYearTwo(state) {
      const totalCostPerPatient = (parseFloat(state.byovizNetCost, 10) * state.byovizPdrContinuation);
      let numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabPdr, 10) * (parseFloat(state.byovizYearTwoShareSwitch, 10) / 100)));
      numberOfPatients *= state.averageNumberOfEyesAffectedPdr;
      return (numberOfPatients * totalCostPerPatient);
    },
    switchPatientsCurrentCostPdrBiosimilarYearTwo(state) {
      const totalCostPerPatient = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarPdrContinuation, 10));
      let numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabPdr, 10) * (parseFloat(state.biosimilarYearTwoShareSwitch, 10) / 100)));
      numberOfPatients *= state.averageNumberOfEyesAffectedPdr;
      return (numberOfPatients * totalCostPerPatient);
    },
    switchPatientsCurrentCostPdrLucentisYearThree(state) {
      const totalCostPerPatient = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisPdrContinuation, 10));
      let numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabPdr, 10) * (parseFloat(state.lucentisYearThreeShareSwitch, 10) / 100)));
      numberOfPatients *= state.averageNumberOfEyesAffectedPdr;
      return (numberOfPatients * totalCostPerPatient);
    },
    switchPatientsCurrentCostPdrByovizYearThree(state) {
      const totalCostPerPatient = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizPdrContinuation, 10));
      let numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabPdr, 10) * (parseFloat(state.byovizYearThreeShareSwitch, 10) / 100)));
      numberOfPatients *= state.averageNumberOfEyesAffectedPdr;
      return (numberOfPatients * totalCostPerPatient);
    },
    switchPatientsCurrentCostPdrBiosimilarYearThree(state) {
      const totalCostPerPatient = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarPdrContinuation, 10));
      let numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabPdr, 10) * (parseFloat(state.biosimilarYearThreeShareSwitch, 10) / 100)));
      numberOfPatients *= state.averageNumberOfEyesAffectedPdr;
      return (numberOfPatients * totalCostPerPatient);
    },
    // RVO costs current switch
    switchPatientsCurrentCostRvoLucentisYearOne(state) {
      const totalCostPerPatient = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisRvoContinuation, 10));
      let numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabRvo, 10) * (parseFloat(state.lucentisYearOneShareSwitch, 10) / 100)));
      numberOfPatients *= state.averageNumberOfEyesAffectedRvo;
      return (numberOfPatients * totalCostPerPatient);
    },
    switchPatientsCurrentCostRvoByovizYearOne(state) {
      const totalCostPerPatient = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizRvoContinuation, 10));
      let numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabRvo, 10) * (parseFloat(state.byovizYearOneShareSwitch, 10) / 100)));
      numberOfPatients *= state.averageNumberOfEyesAffectedRvo;
      return (numberOfPatients * totalCostPerPatient);
    },
    switchPatientsCurrentCostRvoBiosimilarYearOne(state) {
      const totalCostPerPatient = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarRvoContinuation, 10));
      let numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabRvo, 10) * (parseFloat(state.biosimilarYearOneShareSwitch, 10) / 100)));
      numberOfPatients *= state.averageNumberOfEyesAffectedRvo;
      return (numberOfPatients * totalCostPerPatient);
    },
    switchPatientsCurrentCostRvoLucentisYearTwo(state) {
      const totalCostPerPatient = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisRvoContinuation, 10));
      let numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabRvo, 10) * (parseFloat(state.lucentisYearTwoShareSwitch, 10) / 100)));
      numberOfPatients *= state.averageNumberOfEyesAffectedRvo;
      return (numberOfPatients * totalCostPerPatient);
    },
    switchPatientsCurrentCostRvoByovizYearTwo(state) {
      const totalCostPerPatient = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizRvoContinuation, 10));
      let numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabRvo, 10) * (parseFloat(state.byovizYearTwoShareSwitch, 10) / 100)));
      numberOfPatients *= state.averageNumberOfEyesAffectedRvo;
      return (numberOfPatients * totalCostPerPatient);
    },
    switchPatientsCurrentCostRvoBiosimilarYearTwo(state) {
      const totalCostPerPatient = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarRvoContinuation, 10));
      let numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabRvo, 10) * (parseFloat(state.biosimilarYearTwoShareSwitch, 10) / 100)));
      numberOfPatients *= state.averageNumberOfEyesAffectedRvo;
      return (numberOfPatients * totalCostPerPatient);
    },
    switchPatientsCurrentCostRvoLucentisYearThree(state) {
      const totalCostPerPatient = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisRvoContinuation, 10));
      let numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabRvo, 10) * (parseFloat(state.lucentisYearThreeShareSwitch, 10) / 100)));
      numberOfPatients *= state.averageNumberOfEyesAffectedRvo;
      return (numberOfPatients * totalCostPerPatient);
    },
    switchPatientsCurrentCostRvoByovizYearThree(state) {
      const totalCostPerPatient = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizRvoContinuation, 10));
      let numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabRvo, 10) * (parseFloat(state.byovizYearThreeShareSwitch, 10) / 100)));
      numberOfPatients *= state.averageNumberOfEyesAffectedRvo;
      return (numberOfPatients * totalCostPerPatient);
    },
    switchPatientsCurrentCostRvoBiosimilarYearThree(state) {
      const totalCostPerPatient = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarRvoContinuation, 10));
      let numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabRvo, 10) * (parseFloat(state.biosimilarYearThreeShareSwitch, 10) / 100)));
      numberOfPatients *= state.averageNumberOfEyesAffectedRvo;
      return (numberOfPatients * totalCostPerPatient);
    },
    // CNV costs current switch
    switchPatientsCurrentCostCnvLucentisYearOne(state) {
      const totalCostPerPatient = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisCnvContinuation, 10));
      let numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabCnv, 10) * (parseFloat(state.lucentisYearOneShareSwitch, 10) / 100)));
      numberOfPatients *= state.averageNumberOfEyesAffectedCnv;
      return (numberOfPatients * totalCostPerPatient);
    },
    switchPatientsCurrentCostCnvByovizYearOne(state) {
      const totalCostPerPatient = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizCnvContinuation, 10));
      let numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabCnv, 10) * (parseFloat(state.byovizYearOneShareSwitch, 10) / 100)));
      numberOfPatients *= state.averageNumberOfEyesAffectedCnv;
      return (numberOfPatients * totalCostPerPatient);
    },
    switchPatientsCurrentCostCnvBiosimilarYearOne(state) {
      const totalCostPerPatient = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarCnvContinuation, 10));
      let numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabCnv, 10) * (parseFloat(state.biosimilarYearOneShareSwitch, 10) / 100)));
      numberOfPatients *= state.averageNumberOfEyesAffectedCnv;
      return (numberOfPatients * totalCostPerPatient);
    },
    switchPatientsCurrentCostCnvLucentisYearTwo(state) {
      const totalCostPerPatient = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisCnvContinuation, 10));
      let numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabCnv, 10) * (parseFloat(state.lucentisYearTwoShareSwitch, 10) / 100)));
      numberOfPatients *= state.averageNumberOfEyesAffectedCnv;
      return (numberOfPatients * totalCostPerPatient);
    },
    switchPatientsCurrentCostCnvByovizYearTwo(state) {
      const totalCostPerPatient = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizCnvContinuation, 10));
      let numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabCnv, 10) * (parseFloat(state.byovizYearTwoShareSwitch, 10) / 100)));
      numberOfPatients *= state.averageNumberOfEyesAffectedCnv;
      return (numberOfPatients * totalCostPerPatient);
    },
    switchPatientsCurrentCostCnvBiosimilarYearTwo(state) {
      const totalCostPerPatient = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarCnvContinuation, 10));
      let numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabCnv, 10) * (parseFloat(state.biosimilarYearTwoShareSwitch, 10) / 100)));
      numberOfPatients *= state.averageNumberOfEyesAffectedCnv;
      return (numberOfPatients * totalCostPerPatient);
    },
    switchPatientsCurrentCostCnvLucentisYearThree(state) {
      const totalCostPerPatient = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisCnvContinuation, 10));
      let numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabCnv, 10) * (parseFloat(state.lucentisYearThreeShareSwitch, 10) / 100)));
      numberOfPatients *= state.averageNumberOfEyesAffectedCnv;
      return (numberOfPatients * totalCostPerPatient);
    },
    switchPatientsCurrentCostCnvByovizYearThree(state) {
      const totalCostPerPatient = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizCnvContinuation, 10));
      let numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabCnv, 10) * (parseFloat(state.byovizYearThreeShareSwitch, 10) / 100)));
      numberOfPatients *= state.averageNumberOfEyesAffectedCnv;
      return (numberOfPatients * totalCostPerPatient);
    },
    switchPatientsCurrentCostCnvBiosimilarYearThree(state) {
      const totalCostPerPatient = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarCnvContinuation, 10));
      let numberOfPatients = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabCnv, 10) * (parseFloat(state.biosimilarYearThreeShareSwitch, 10) / 100)));
      numberOfPatients *= state.averageNumberOfEyesAffectedCnv;
      return (numberOfPatients * totalCostPerPatient);
    },
    // Wamd costs future switch
    switchPatientsCurrentCostWamdLucentisYearOneFuture(state) {
      const totalCostPerPatient = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisWamdContinuation, 10));
      const totalPatients = (parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabWamd, 10));
      const toTreat = totalPatients - (state.byoovizWamdYearOneFuturePatients + state.biosimilarWamdYearOneFuturePatients);
      const eyes = state.averageNumberOfEyesAffectedWamd;
      const total = (toTreat * totalCostPerPatient);
      return (total * eyes);
    },
    switchPatientsCurrentCostWamdByovizYearOneFuture(state) {
      const totalCostPerPatient = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizWamdContinuation, 10));
      const eyes = state.averageNumberOfEyesAffectedWamd;
      const total = (state.byoovizWamdYearOneFuturePatients * totalCostPerPatient);
      return (total * eyes);
    },
    switchPatientsCurrentCostWamdBiosimilarYearOneFuture(state) {
      const totalCostPerPatient = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarWamdContinuation, 10));
      const eyes = state.averageNumberOfEyesAffectedWamd;
      const total = (state.biosimilarWamdYearOneFuturePatients * totalCostPerPatient);
      return (total * eyes);
    },
    switchPatientsThreeYearFutureLucentisWamd(state) {
      const totalPatients = (parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabWamd, 10));
      const toTreatY1 = totalPatients - (state.byoovizWamdYearOneFuturePatients + state.biosimilarWamdYearOneFuturePatients);
      const toTreatFullYear1 = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabWamd, 10) * (parseFloat(state.lucentisYearOneFutureShareSwitch, 10) / 100)));
      const fullYear2 = totalPatients - (state.byoovizWamdYearTwoFuturePatients + state.biosimilarWamdYearTwoFuturePatients);
      const fullYear3 = totalPatients - (state.byoovizWamdYearThreeFuturePatients + state.biosimilarWamdYearThreeFuturePatients);
      const continuationDose = parseInt(state.lucentisWamdContinuation, 10);
      const startingDose = parseInt(state.lucentisWamdLoading, 10) + parseInt(state.lucentisWamdMaintenance, 10);
      const volumeYear1 = (toTreatY1 * continuationDose);
      const volumeYear2 = ((toTreatFullYear1 * continuationDose) + ((fullYear2 - toTreatFullYear1) * startingDose));
      const volumeYear3 = ((fullYear2 * continuationDose) + ((fullYear3 - fullYear2) * startingDose));
      const costY1 = (volumeYear1 * state.lucentisNetCost);
      const costY2 = (volumeYear2 * state.lucentisNetCost);
      const costY3 = (volumeYear3 * state.lucentisNetCost);
      const eyes = state.averageNumberOfEyesAffectedWamd;
      const total = (costY1 + costY2 + costY3);
      return (total * eyes);
    },
    switchPatientsThreeYearFutureByovizWamd(state) {
      const toTreatY1 = state.byoovizWamdYearOneFuturePatients;
      const toTreatFullYear1 = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabWamd, 10) * (parseFloat(state.byovizYearOneFutureShareSwitch, 10) / 100)));
      const fullYear2 = state.byoovizWamdYearTwoFuturePatients;
      const fullYear3 = state.byoovizWamdYearThreeFuturePatients;
      const continuationDose = parseInt(state.byovizWamdContinuation, 10);
      const startingDose = parseInt(state.byovizWamdLoading, 10) + parseInt(state.byovizWamdMaintenance, 10);
      const volumeYear1 = (toTreatY1 * continuationDose);
      const volumeYear2 = ((toTreatFullYear1 * continuationDose) + ((fullYear2 - toTreatFullYear1) * startingDose));
      const volumeYear3 = ((fullYear2 * continuationDose) + ((fullYear3 - fullYear2) * startingDose));
      const costY1 = (volumeYear1 * state.byovizNetCost);
      const costY2 = (volumeYear2 * state.byovizNetCost);
      const costY3 = (volumeYear3 * state.byovizNetCost);
      const eyes = state.averageNumberOfEyesAffectedWamd;
      const total = (costY1 + costY2 + costY3);
      return (total * eyes);
    },
    switchPatientsThreeYearFutureBiosimilarWamd(state) {
      const toTreatY1 = state.biosimilarWamdYearOneFuturePatients;
      const toTreatFullYear1 = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabWamd, 10) * (parseFloat(state.biosimilarYearOneFutureShareSwitch, 10) / 100)));
      const fullYear2 = state.biosimilarWamdYearTwoFuturePatients;
      const fullYear3 = state.biosimilarWamdYearThreeFuturePatients;
      const continuationDose = parseInt(state.biosimilarWamdContinuation, 10);
      const startingDose = parseInt(state.biosimilarWamdLoading, 10) + parseInt(state.biosimilarWamdMaintenance, 10);
      const volumeYear1 = (toTreatY1 * continuationDose);
      const volumeYear2 = ((toTreatFullYear1 * continuationDose) + ((fullYear2 - toTreatFullYear1) * startingDose));
      const volumeYear3 = ((fullYear2 * continuationDose) + ((fullYear3 - fullYear2) * startingDose));
      const costY1 = (volumeYear1 * state.biosimilarNetCost);
      const costY2 = (volumeYear2 * state.biosimilarNetCost);
      const costY3 = (volumeYear3 * state.biosimilarNetCost);
      const eyes = state.averageNumberOfEyesAffectedWamd;
      const total = (costY1 + costY2 + costY3);
      return (total * eyes);
    },
    // DME costs future switch
    switchPatientsCurrentCostDmeLucentisYearOneFuture(state) {
      const totalCostPerPatient = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisDmeContinuation, 10));
      const totalPatients = (parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabDme, 10));
      const toTreat = totalPatients - (state.byoovizDmeYearOneFuturePatients + state.biosimilarDmeYearOneFuturePatients);
      const eyes = state.averageNumberOfEyesAffectedDme;
      const total = (toTreat * totalCostPerPatient);
      return (total * eyes);
    },
    switchPatientsCurrentCostDmeByovizYearOneFuture(state) {
      const totalCostPerPatient = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizDmeContinuation, 10));
      const eyes = state.averageNumberOfEyesAffectedDme;
      const total = (state.byoovizDmeYearOneFuturePatients * totalCostPerPatient);
      return (total * eyes);
    },
    switchPatientsCurrentCostDmeBiosimilarYearOneFuture(state) {
      const totalCostPerPatient = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarDmeContinuation, 10));
      const eyes = state.averageNumberOfEyesAffectedDme;
      const total = (state.biosimilarDmeYearOneFuturePatients * totalCostPerPatient);
      return (total * eyes);
    },
    switchPatientsThreeYearFutureLucentisDme(state) {
      const totalPatients = (parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabDme, 10));
      const toTreatY1 = totalPatients - (state.byoovizDmeYearOneFuturePatients + state.biosimilarDmeYearOneFuturePatients);
      const toTreatFullYear1 = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabDme, 10) * (parseFloat(state.lucentisYearOneFutureShareSwitch, 10) / 100)));
      const fullYear2 = totalPatients - (state.byoovizDmeYearTwoFuturePatients + state.biosimilarDmeYearTwoFuturePatients);
      const fullYear3 = totalPatients - (state.byoovizDmeYearThreeFuturePatients + state.biosimilarDmeYearThreeFuturePatients);
      const continuationDose = parseInt(state.lucentisDmeContinuation, 10);
      const startingDose = parseInt(state.lucentisDmeLoading, 10) + parseInt(state.lucentisDmeMaintenance, 10);
      const volumeYear1 = (toTreatY1 * continuationDose);
      const volumeYear2 = ((toTreatFullYear1 * continuationDose) + ((fullYear2 - toTreatFullYear1) * startingDose));
      const volumeYear3 = ((fullYear2 * continuationDose) + ((fullYear3 - fullYear2) * startingDose));
      const costY1 = (volumeYear1 * state.lucentisNetCost);
      const costY2 = (volumeYear2 * state.lucentisNetCost);
      const costY3 = (volumeYear3 * state.lucentisNetCost);
      const eyes = state.averageNumberOfEyesAffectedDme;
      const total = (costY1 + costY2 + costY3);
      return (total * eyes);
    },
    switchPatientsThreeYearFutureByovizDme(state) {
      const toTreatY1 = state.byoovizDmeYearOneFuturePatients;
      const toTreatFullYear1 = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabDme, 10) * (parseFloat(state.byovizYearOneFutureShareSwitch, 10) / 100)));
      const fullYear2 = state.byoovizDmeYearTwoFuturePatients;
      const fullYear3 = state.byoovizDmeYearThreeFuturePatients;
      const continuationDose = parseInt(state.byovizDmeContinuation, 10);
      const startingDose = parseInt(state.byovizDmeLoading, 10) + parseInt(state.byovizDmeMaintenance, 10);
      const volumeYear1 = (toTreatY1 * continuationDose);
      const volumeYear2 = ((toTreatFullYear1 * continuationDose) + ((fullYear2 - toTreatFullYear1) * startingDose));
      const volumeYear3 = ((fullYear2 * continuationDose) + ((fullYear3 - fullYear2) * startingDose));
      const costY1 = (volumeYear1 * state.byovizNetCost);
      const costY2 = (volumeYear2 * state.byovizNetCost);
      const costY3 = (volumeYear3 * state.byovizNetCost);
      const eyes = state.averageNumberOfEyesAffectedDme;
      const total = (costY1 + costY2 + costY3);
      return (total * eyes);
    },
    switchPatientsThreeYearFutureBiosimilarDme(state) {
      const toTreatY1 = state.biosimilarDmeYearOneFuturePatients;
      const toTreatFullYear1 = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabDme, 10) * (parseFloat(state.biosimilarYearOneFutureShareSwitch, 10) / 100)));
      const fullYear2 = state.biosimilarDmeYearTwoFuturePatients;
      const fullYear3 = state.biosimilarDmeYearThreeFuturePatients;
      const continuationDose = parseInt(state.biosimilarDmeContinuation, 10);
      const startingDose = parseInt(state.biosimilarDmeLoading, 10) + parseInt(state.biosimilarDmeMaintenance, 10);
      const volumeYear1 = (toTreatY1 * continuationDose);
      const volumeYear2 = ((toTreatFullYear1 * continuationDose) + ((fullYear2 - toTreatFullYear1) * startingDose));
      const volumeYear3 = ((fullYear2 * continuationDose) + ((fullYear3 - fullYear2) * startingDose));
      const costY1 = (volumeYear1 * state.biosimilarNetCost);
      const costY2 = (volumeYear2 * state.biosimilarNetCost);
      const costY3 = (volumeYear3 * state.biosimilarNetCost);
      const eyes = state.averageNumberOfEyesAffectedDme;
      const total = (costY1 + costY2 + costY3);
      return (total * eyes);
    },
    // PDR costs future switch
    switchPatientsCurrentCostPdrLucentisYearOneFuture(state) {
      const totalCostPerPatient = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisPdrContinuation, 10));
      const totalPatients = (parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabPdr, 10));
      const toTreat = totalPatients - (state.byoovizPdrYearOneFuturePatients + state.biosimilarPdrYearOneFuturePatients);
      const eyes = state.averageNumberOfEyesAffectedPdr;
      const total = (toTreat * totalCostPerPatient);
      return (total * eyes);
    },
    switchPatientsCurrentCostPdrByovizYearOneFuture(state) {
      const totalCostPerPatient = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizPdrContinuation, 10));
      const eyes = state.averageNumberOfEyesAffectedPdr;
      const total = (state.byoovizPdrYearOneFuturePatients * totalCostPerPatient);
      return (total * eyes);
    },
    switchPatientsCurrentCostPdrBiosimilarYearOneFuture(state) {
      const totalCostPerPatient = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarPdrContinuation, 10));
      const eyes = state.averageNumberOfEyesAffectedPdr;
      const total = (state.biosimilarPdrYearOneFuturePatients * totalCostPerPatient);
      return (total * eyes);
    },
    switchPatientsThreeYearFutureLucentisPdr(state) {
      const totalPatients = (parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabPdr, 10));
      const toTreatY1 = totalPatients - (state.byoovizPdrYearOneFuturePatients + state.biosimilarPdrYearOneFuturePatients);
      const toTreatFullYear1 = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabPdr, 10) * (parseFloat(state.lucentisYearOneFutureShareSwitch, 10) / 100)));
      const fullYear2 = totalPatients - (state.byoovizPdrYearTwoFuturePatients + state.biosimilarPdrYearTwoFuturePatients);
      const fullYear3 = totalPatients - (state.byoovizPdrYearThreeFuturePatients + state.biosimilarPdrYearThreeFuturePatients);
      const continuationDose = parseInt(state.lucentisPdrContinuation, 10);
      const startingDose = parseInt(state.lucentisPdrLoading, 10) + parseInt(state.lucentisPdrMaintenance, 10);
      const volumeYear1 = (toTreatY1 * continuationDose);
      const volumeYear2 = ((toTreatFullYear1 * continuationDose) + ((fullYear2 - toTreatFullYear1) * startingDose));
      const volumeYear3 = ((fullYear2 * continuationDose) + ((fullYear3 - fullYear2) * startingDose));
      const costY1 = (volumeYear1 * state.lucentisNetCost);
      const costY2 = (volumeYear2 * state.lucentisNetCost);
      const costY3 = (volumeYear3 * state.lucentisNetCost);
      const eyes = state.averageNumberOfEyesAffectedPdr;
      const total = (costY1 + costY2 + costY3);
      return (total * eyes);
    },
    switchPatientsThreeYearFutureByovizPdr(state) {
      const toTreatY1 = state.byoovizPdrYearOneFuturePatients;
      const toTreatFullYear1 = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabPdr, 10) * (parseFloat(state.byovizYearOneFutureShareSwitch, 10) / 100)));
      const fullYear2 = state.byoovizPdrYearTwoFuturePatients;
      const fullYear3 = state.byoovizPdrYearThreeFuturePatients;
      const continuationDose = parseInt(state.byovizPdrContinuation, 10);
      const startingDose = parseInt(state.byovizPdrLoading, 10) + parseInt(state.byovizPdrMaintenance, 10);
      const volumeYear1 = (toTreatY1 * continuationDose);
      const volumeYear2 = ((toTreatFullYear1 * continuationDose) + ((fullYear2 - toTreatFullYear1) * startingDose));
      const volumeYear3 = ((fullYear2 * continuationDose) + ((fullYear3 - fullYear2) * startingDose));
      const costY1 = (volumeYear1 * state.byovizNetCost);
      const costY2 = (volumeYear2 * state.byovizNetCost);
      const costY3 = (volumeYear3 * state.byovizNetCost);
      const eyes = state.averageNumberOfEyesAffectedPdr;
      const total = (costY1 + costY2 + costY3);
      return (total * eyes);
    },
    switchPatientsThreeYearFutureBiosimilarPdr(state) {
      const toTreatY1 = state.biosimilarPdrYearOneFuturePatients;
      const toTreatFullYear1 = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabPdr, 10) * (parseFloat(state.biosimilarYearOneFutureShareSwitch, 10) / 100)));
      const fullYear2 = state.biosimilarPdrYearTwoFuturePatients;
      const fullYear3 = state.biosimilarPdrYearThreeFuturePatients;
      const continuationDose = parseInt(state.biosimilarPdrContinuation, 10);
      const startingDose = parseInt(state.biosimilarPdrLoading, 10) + parseInt(state.biosimilarPdrMaintenance, 10);
      const volumeYear1 = (toTreatY1 * continuationDose);
      const volumeYear2 = ((toTreatFullYear1 * continuationDose) + ((fullYear2 - toTreatFullYear1) * startingDose));
      const volumeYear3 = ((fullYear2 * continuationDose) + ((fullYear3 - fullYear2) * startingDose));
      const costY1 = (volumeYear1 * state.biosimilarNetCost);
      const costY2 = (volumeYear2 * state.biosimilarNetCost);
      const costY3 = (volumeYear3 * state.biosimilarNetCost);
      const eyes = state.averageNumberOfEyesAffectedPdr;
      const total = (costY1 + costY2 + costY3);
      return (total * eyes);
    },
    // RVO costs future switch
    switchPatientsCurrentCostRvoLucentisYearOneFuture(state) {
      const totalCostPerPatient = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisRvoContinuation, 10));
      const totalPatients = (parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabRvo, 10));
      const toTreat = totalPatients - (state.byoovizRvoYearOneFuturePatients + state.biosimilarRvoYearOneFuturePatients);
      const eyes = state.averageNumberOfEyesAffectedRvo;
      const total = (toTreat * totalCostPerPatient);
      return (total * eyes);
    },
    switchPatientsCurrentCostRvoByovizYearOneFuture(state) {
      const totalCostPerPatient = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizRvoContinuation, 10));
      const eyes = state.averageNumberOfEyesAffectedRvo;
      const total = (state.byoovizRvoYearOneFuturePatients * totalCostPerPatient);
      return (total * eyes);
    },
    switchPatientsCurrentCostRvoBiosimilarYearOneFuture(state) {
      const totalCostPerPatient = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarRvoContinuation, 10));
      const eyes = state.averageNumberOfEyesAffectedRvo;
      const total = (state.biosimilarRvoYearOneFuturePatients * totalCostPerPatient);
      return (total * eyes);
    },
    switchPatientsThreeYearFutureLucentisRvo(state) {
      const totalPatients = (parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabRvo, 10));
      const toTreatY1 = totalPatients - (state.byoovizRvoYearOneFuturePatients + state.biosimilarRvoYearOneFuturePatients);
      const toTreatFullYear1 = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabRvo, 10) * (parseFloat(state.lucentisYearOneFutureShareSwitch, 10) / 100)));
      const fullYear2 = totalPatients - (state.byoovizRvoYearTwoFuturePatients + state.biosimilarRvoYearTwoFuturePatients);
      const fullYear3 = totalPatients - (state.byoovizRvoYearThreeFuturePatients + state.biosimilarRvoYearThreeFuturePatients);
      const continuationDose = parseInt(state.lucentisRvoContinuation, 10);
      const startingDose = parseInt(state.lucentisRvoLoading, 10) + parseInt(state.lucentisRvoMaintenance, 10);
      const volumeYear1 = (toTreatY1 * continuationDose);
      const volumeYear2 = ((toTreatFullYear1 * continuationDose) + ((fullYear2 - toTreatFullYear1) * startingDose));
      const volumeYear3 = ((fullYear2 * continuationDose) + ((fullYear3 - fullYear2) * startingDose));
      const costY1 = (volumeYear1 * state.lucentisNetCost);
      const costY2 = (volumeYear2 * state.lucentisNetCost);
      const costY3 = (volumeYear3 * state.lucentisNetCost);
      const eyes = state.averageNumberOfEyesAffectedRvo;
      const total = (costY1 + costY2 + costY3);
      return (total * eyes);
    },
    switchPatientsThreeYearFutureByovizRvo(state) {
      const toTreatY1 = state.byoovizRvoYearOneFuturePatients;
      const toTreatFullYear1 = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabRvo, 10) * (parseFloat(state.byovizYearOneFutureShareSwitch, 10) / 100)));
      const fullYear2 = state.byoovizRvoYearTwoFuturePatients;
      const fullYear3 = state.byoovizRvoYearThreeFuturePatients;
      const continuationDose = parseInt(state.byovizRvoContinuation, 10);
      const startingDose = parseInt(state.byovizRvoLoading, 10) + parseInt(state.byovizRvoMaintenance, 10);
      const volumeYear1 = (toTreatY1 * continuationDose);
      const volumeYear2 = ((toTreatFullYear1 * continuationDose) + ((fullYear2 - toTreatFullYear1) * startingDose));
      const volumeYear3 = ((fullYear2 * continuationDose) + ((fullYear3 - fullYear2) * startingDose));
      const costY1 = (volumeYear1 * state.byovizNetCost);
      const costY2 = (volumeYear2 * state.byovizNetCost);
      const costY3 = (volumeYear3 * state.byovizNetCost);
      const eyes = state.averageNumberOfEyesAffectedRvo;
      const total = (costY1 + costY2 + costY3);
      return (total * eyes);
    },
    switchPatientsThreeYearFutureBiosimilarRvo(state) {
      const toTreatY1 = state.biosimilarRvoYearOneFuturePatients;
      const toTreatFullYear1 = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabRvo, 10) * (parseFloat(state.biosimilarYearOneFutureShareSwitch, 10) / 100)));
      const fullYear2 = state.biosimilarRvoYearTwoFuturePatients;
      const fullYear3 = state.biosimilarRvoYearThreeFuturePatients;
      const continuationDose = parseInt(state.biosimilarRvoContinuation, 10);
      const startingDose = parseInt(state.biosimilarRvoLoading, 10) + parseInt(state.biosimilarRvoMaintenance, 10);
      const volumeYear1 = (toTreatY1 * continuationDose);
      const volumeYear2 = ((toTreatFullYear1 * continuationDose) + ((fullYear2 - toTreatFullYear1) * startingDose));
      const volumeYear3 = ((fullYear2 * continuationDose) + ((fullYear3 - fullYear2) * startingDose));
      const costY1 = (volumeYear1 * state.biosimilarNetCost);
      const costY2 = (volumeYear2 * state.biosimilarNetCost);
      const costY3 = (volumeYear3 * state.biosimilarNetCost);
      const eyes = state.averageNumberOfEyesAffectedRvo;
      const total = (costY1 + costY2 + costY3);
      return (total * eyes);
    },
    // CNV costs future switch
    switchPatientsCurrentCostCnvLucentisYearOneFuture(state) {
      const totalCostPerPatient = (parseFloat(state.lucentisNetCost, 10) * parseInt(state.lucentisCnvContinuation, 10));
      const totalPatients = (parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabCnv, 10));
      const toTreat = totalPatients - (state.byoovizCnvYearOneFuturePatients + state.biosimilarCnvYearOneFuturePatients);
      const eyes = state.averageNumberOfEyesAffectedCnv;
      const total = (toTreat * totalCostPerPatient);
      return (total * eyes);
    },
    switchPatientsCurrentCostCnvByovizYearOneFuture(state) {
      const totalCostPerPatient = (parseFloat(state.byovizNetCost, 10) * parseInt(state.byovizCnvContinuation, 10));
      const eyes = state.averageNumberOfEyesAffectedCnv;
      const total = (state.byoovizCnvYearOneFuturePatients * totalCostPerPatient);
      return (total * eyes);
    },
    switchPatientsCurrentCostCnvBiosimilarYearOneFuture(state) {
      const totalCostPerPatient = (parseFloat(state.biosimilarNetCost, 10) * parseInt(state.biosimilarCnvContinuation, 10));
      const eyes = state.averageNumberOfEyesAffectedCnv;
      const total = (state.biosimilarCnvYearOneFuturePatients * totalCostPerPatient);
      return (total * eyes);
    },
    switchPatientsThreeYearFutureLucentisCnv(state) {
      const totalPatients = (parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabCnv, 10));
      const toTreatY1 = totalPatients - (state.byoovizCnvYearOneFuturePatients + state.biosimilarCnvYearOneFuturePatients);
      const toTreatFullYear1 = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabCnv, 10) * (parseFloat(state.lucentisYearOneFutureShareSwitch, 10) / 100)));
      const fullYear2 = totalPatients - (state.byoovizCnvYearTwoFuturePatients + state.biosimilarCnvYearTwoFuturePatients);
      const fullYear3 = totalPatients - (state.byoovizCnvYearThreeFuturePatients + state.biosimilarCnvYearThreeFuturePatients);
      const continuationDose = parseInt(state.lucentisCnvContinuation, 10);
      const startingDose = parseInt(state.lucentisCnvTotal, 10);
      const volumeYear1 = (toTreatY1 * continuationDose);
      const volumeYear2 = ((toTreatFullYear1 * continuationDose) + ((fullYear2 - toTreatFullYear1) * startingDose));
      const volumeYear3 = ((fullYear2 * continuationDose) + ((fullYear3 - fullYear2) * startingDose));
      const costY1 = (volumeYear1 * state.lucentisNetCost);
      const costY2 = (volumeYear2 * state.lucentisNetCost);
      const costY3 = (volumeYear3 * state.lucentisNetCost);
      const eyes = state.averageNumberOfEyesAffectedCnv;
      const total = (costY1 + costY2 + costY3);
      return (total * eyes);
    },
    switchPatientsThreeYearFutureByovizCnv(state) {
      const toTreatY1 = state.byoovizCnvYearOneFuturePatients;
      const toTreatFullYear1 = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabCnv, 10) * (parseFloat(state.byovizYearOneFutureShareSwitch, 10) / 100)));
      const fullYear2 = state.byoovizCnvYearTwoFuturePatients;
      const fullYear3 = state.byoovizCnvYearThreeFuturePatients;
      const continuationDose = parseInt(state.byovizCnvContinuation, 10);
      const startingDose = parseInt(state.byovizCnvTotal, 10);
      const volumeYear1 = (toTreatY1 * continuationDose);
      const volumeYear2 = ((toTreatFullYear1 * continuationDose) + ((fullYear2 - toTreatFullYear1) * startingDose));
      const volumeYear3 = ((fullYear2 * continuationDose) + ((fullYear3 - fullYear2) * startingDose));
      const costY1 = (volumeYear1 * state.byovizNetCost);
      const costY2 = (volumeYear2 * state.byovizNetCost);
      const costY3 = (volumeYear3 * state.byovizNetCost);
      const eyes = state.averageNumberOfEyesAffectedCnv;
      const total = (costY1 + costY2 + costY3);
      return (total * eyes);
    },
    switchPatientsThreeYearFutureBiosimilarCnv(state) {
      const toTreatY1 = state.biosimilarCnvYearOneFuturePatients;
      const toTreatFullYear1 = Math.round((parseInt(state.numberOfPatientsCurrentlyTreatedWithRanibizumabCnv, 10) * (parseFloat(state.biosimilarYearOneFutureShareSwitch, 10) / 100)));
      const fullYear2 = state.biosimilarCnvYearTwoFuturePatients;
      const fullYear3 = state.biosimilarCnvYearThreeFuturePatients;
      const continuationDose = parseInt(state.biosimilarCnvContinuation, 10);
      const startingDose = parseInt(state.biosimilarCnvTotal, 10);
      const volumeYear1 = (toTreatY1 * continuationDose);
      const volumeYear2 = ((toTreatFullYear1 * continuationDose) + ((fullYear2 - toTreatFullYear1) * startingDose));
      const volumeYear3 = ((fullYear2 * continuationDose) + ((fullYear3 - fullYear2) * startingDose));
      const costY1 = (volumeYear1 * state.biosimilarNetCost);
      const costY2 = (volumeYear2 * state.biosimilarNetCost);
      const costY3 = (volumeYear3 * state.biosimilarNetCost);
      const eyes = state.averageNumberOfEyesAffectedCnv;
      const total = (costY1 + costY2 + costY3);
      return (total * eyes);
    },
  },
});
